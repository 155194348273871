import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-categories-style-one',
  templateUrl: './categories-style-one.component.html',
  styleUrls: ['./categories-style-one.component.scss'],
})
export class CategoriesStyleOneComponent implements OnInit {
  data: any[] = [];
  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.http
      .get(`${environment.BASE_URL}Api/Webinars/GetTopWebinars/5`)
      .subscribe((data: any) => (this.data = data));
  }

  categoriesSlides: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  };
}
