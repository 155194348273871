<app-header-style-two></app-header-style-two>      
<div class=" page-title-area page-title-style-three item-bg2">
  <div class="container">
    <div class="page-title-content">
            <ul>
                <li>HUB</li>
                <li>Case Studies</li>
            </ul>
              <h2>Case Study Details</h2>          
        </div>      
  </div>
</div>

<div class="container" style="margin-top: 20px;">
    <h1 style="margin-top: 120px;">{{data.Title}}</h1>
    <div class="container-fluid">
    <div class="row">
      <div class="col-lg-5" style="padding: 20px;  border: #82ae74 2px solid; margin: 5px;">
        <div class="home-slides">
          <owl-carousel-o [options]="homeSlides" >
              <ng-template carouselSlide *ngFor="let item of data.S_CaseStudies_Img">
                  <div style="height:500px;"  class="main-banner" [ngStyle]="{
                      'background-image': 'url(' + BASE_IMG_URL.slice(0,-1) + item.Url + ')'}">
                      <div class="d-table">
                          <div class="d-table-cell">
                              <div class="container">
                                  <div class="main-banner-content">
                                   
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </ng-template>
      
          </owl-carousel-o>
      </div>
      </div> 
      <div class="col-lg-6">
        <div class="container" >
          <ul class="nav nav-tabs " id="CaseStudyTab" role="tablist">
        
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="Description-tab" data-bs-toggle="tab" data-bs-target="#Description" type="button" role="tab" aria-controls="Description" aria-selected="true">Description</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="Videos-tab" data-bs-toggle="tab" data-bs-target="#Videos" type="button" role="tab" aria-controls="Videos" aria-selected="true">Videos</button>
            </li>
        
            <li class="nav-item dropdown">
              <button class="nav-link" id="WEFNexus-tab" data-bs-toggle="tab" data-bs-target="#WEFNexus"  type="button" role="tab" aria-controls="WEFNexus" aria-selected="false">WEF Nexus</button>
        

          </li>

            <!-- <li class="nav-item" role="presentation">
              <button class="nav-link" id="WEFNexus-tab" data-bs-toggle="tab" data-bs-target="#WEFNexus" type="button" role="tab" aria-controls="WEFNexus" aria-selected="false">WEF Nexus</button>
            </li>
         -->
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="Experments-tab" data-bs-toggle="tab" data-bs-target="#Experments" type="button" role="tab" aria-controls="Experments" aria-selected="false">Experiments</button>
            </li>
        
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="Data-tab" data-bs-toggle="tab" data-bs-target="#Data" type="button" role="tab" aria-controls="Data" aria-selected="false">Data</button>
              </li>
        
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="Links-tab" data-bs-toggle="tab" data-bs-target="#Links" type="button" role="tab" aria-controls="Links" aria-selected="false">Links</button>
              </li>
          </ul>
        
          <div class="tab-content" id="myTabContent">
           
            <div class="tab-pane fade show active" id="Description" role="tabpanel" aria-labelledby="Description-tab">
                <div style="overflow-y: scroll; height:500px; padding: 15px;" [innerHTML]="data.LongDescription"></div>
            </div>
            

            <div class="tab-pane fade" id="WEFNexus" role="tabpanel" aria-labelledby="WEFNexus">
            
              <div style="overflow-y: scroll; height:500px; padding: 15px;">
                <div class="accordion accordion-flush" id="accordionFlushExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        CaseStudies Relations
                      </button>
                    </h2>
                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">
                        <div style="overflow-y: scroll; height:500px; padding: 15px;">
                          <img  style="max-height: 500px;" [src]="BASE_IMG_URL.slice(0,-1) + data.S_CaseStudies_Relations.ImageUrl" >
                          <div  [innerHTML]="data.S_CaseStudies_Relations.Description"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTwo">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        CaseStudies Conflicts
                      </button>
                    </h2>
                    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">
                        <div style="overflow-y: scroll; height:500px; padding: 15px;" >
                          {{data.S_CaseStudies_Conflicts.Description}}
                      </div>
                      </div>
                    </div>
                  </div>
                
                </div>
                
              </div>
            </div>
            
            <div class="tab-pane fade" id="Videos" role="tabpanel" style="margin: 10%;"  aria-labelledby="Videos-tab">
                  
                    <div  class="col-lg-6" *ngFor="let video of data.S_CaseStudies_Video">
                     
                        <div>
                          <ng-container *ngIf="video.Url.includes('youtube')">
                          
                            <iframe width="560" height="315" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen [src]="getlink(video.Url)"></iframe>

                          </ng-container>
                          
                          <ng-container *ngIf="!video.Url.includes('youtube')">
                            <video width="400" height="300" controls>
                              <source type="video/mp4" [src]="getlink(video.Url)">
                            </video>
                          </ng-container>

                          <span>
                            <div id="userNameCard">{{video.Name}}</div>
                            <div class="containBox">
                            
                            </div>
                          </span>
                        </div>
                    </div>


              </div>
        
            <div class="tab-pane fade" id="v-pills-Relations" role="tabpanel" aria-labelledby="pills-Relations">
            
                        <div style="overflow-y: scroll; height:500px; padding: 15px;">
                          <img  style="max-height: 500px;" [src]="BASE_IMG_URL.slice(0,-1) + data.S_CaseStudies_Relations.ImageUrl" >
                          <div  [innerHTML]="data.S_CaseStudies_Relations.Description"></div>
                        </div>
            </div>

            <div class="tab-pane fade" id="v-pills-Conflicts" role="tabpanel" aria-labelledby="pills-Conflicts">
                      <div style="overflow-y: scroll; height:500px; padding: 15px;" >
                          {{data.S_CaseStudies_Conflicts.Description}}
                      </div>
            </div>
        
            <div class="tab-pane fade" id="Experments" role="tabpanel" aria-labelledby="Experments-tab">
              <!-- <ul>
                <li *ngFor="let item of data.S_CaseStudiesExperiments_"><a routerLink="/Experiment-Details/{{item.Id}}">{{item.Name}}</a></li>
              </ul> -->

              <div class="row">
              <div id="cardHover" class="col-lg-6" *ngFor="let item of data.S_CaseStudiesExperiments_">
                <a routerLink="/Experiment-Details/{{item.Id}}">
                  <div>
                    <img src="assets/img/medium.png" id="imageProfile">
                    <span>
                      <div id="userNameCard">{{item.Name}}</div>
                      <div class="containBox">
                        <a class="btn-follow">Details</a>&nbsp;&nbsp;
                        
                      </div>
                    </span>
                  </div>
                </a>
              </div>
            </div>

            </div>
        
            <div class="tab-pane fade" id="Data" role="tabpanel" aria-labelledby="Data-tab">
        
              <div  style="margin-top: 10px;">
              <div>
              <select class="form-select" id="charts" (change)="choosechart($event.target.value)">
              <option value="0">Choose Chart</option>
              <option *ngFor="let item of data.Charts" value="{{item.Id}}"> {{item.Name}} </option>
              </select>
              </div>

              <div>
        
                <canvas *ngIf="ChartType==1"  baseChart 
                [type]="'pie'"
                [datasets]="pieChartDatasets"
                [labels]="pieChartLabels"
                [options]="options"
                [plugins]="pieChartPlugins"
                [legend]="pieChartLegend">
                </canvas>
        
                <canvas *ngIf="ChartType==3" baseChart width="400" height="400"
                [type]="'line'"
                [data]="lineChartData"
                [options]="loptions"
                [legend]="pieChartLegend">
                </canvas>
        
              </div>
              </div>
          
            
            </div>
        
            <div class="tab-pane fade" id="Links" role="tabpanel" aria-labelledby="Links-tab">

              <table class="table table-bordered table-hover" style="margin: 10px;">
                <tr><th>Title</th><th>Link</th></tr>
                
                <tr *ngFor="let excel of data.S_CaseStudies_Excel ; index as i"><td>{{excel.Title}}</td><td><a target="_blank" href="{{BASE_IMG_URL.slice(0,-1)}}{{excel.Url}}"><img src="assets/img/excel.png" id="excel"  style="height: 36px;"></a></td></tr>
                <tr *ngFor="let pdf of data.S_CaseStudies_PDF ; index as i"><td>{{pdf.Title}}</td><td><a target="_blank" href="{{BASE_IMG_URL.slice(0,-1)}}{{pdf.Url}}"><img src="assets/img/pdf (2).png" id="pdf" style="height: 36px;"></a></td></tr>
              
              </table>

              </div>
        
          </div>
        
        </div>
      </div> 
    </div>
    </div>

  
</div>

