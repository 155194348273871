
<div *ngIf="data?.length > 0">
<div class="section-title">
  <span class="sub-title">Community</span>
  <header> <h1>Our Community Top Topics</h1></header>
</div>
<div class="boxes-area">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3 col-md-6" *ngFor="let item of data">
        <div
          class="single-boxes-item"
          [ngStyle]="{
            'background-image': 'url(' + BASE_URL.slice(0,-1) + item.ImageUrl + ')'
          }"
        >
          <h3>{{ item.Title }}</h3>
          <p>{{ item.Body }}</p>
          <a routerLink="/" class="boxes-btn"
            >View All<i class="bx bx-plus"></i
          ></a>
        </div>
      </div>
      <!-- <div class="col-lg-3 col-md-6">
                <div class="single-boxes-item bg-image">
                    <h3>100k Online Courses</h3>
                    <p>In high-demand fields like IT, AI and cloud engineering.</p>
                    <a routerLink="/" class="boxes-btn">View More<i class='bx bx-plus'></i></a>
                </div>
            </div> -->
      <!-- <div class="col-lg-3 col-md-6">
                <div class="single-boxes-item bg-color">
                    <h3>Earn a Certificate</h3>
                    <p>From a leading university in business, computer science, and more.</p>
                    <a routerLink="/" class="boxes-btn">View All<i class='bx bx-plus'></i></a>
                </div>
            </div> -->
      <!-- <div class="col-lg-3 col-md-6">
                <div class="single-boxes-item">
                    <h3>Up Your Skill</h3>
                    <p>With on-demand training and development programs.</p>
                    <a routerLink="/" class="boxes-btn">View More<i class='bx bx-plus'></i></a>
                </div>
            </div> -->
    </div>
  </div>
</div>
</div>
