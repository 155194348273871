<app-header-style-two></app-header-style-two>       


<div class="page-title-area page-title-style-three item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li>HUB</li>
                <li>Mechanism Design</li>
            </ul>
            <h2>Mechanism Design</h2>
        </div>
    </div>
</div>
<!-- ptb-100 -->
<div class="instructor-area pt-100 pb-70" >
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let item of data">
                <div class="single-instructor-member mb-30">
                    <div routerLink="/Mechanism-Design-Details/{{item.id}}" class="member-image">
                        <img src="{{item.img}}" alt="images">
                    </div>
                    <div class="member-content">
                        <h3><a routerLink="/Mechanism-Design-Details/{{item.id}}">{{ item.title}}</a></h3>
                        <!-- <span>OOP Developer</span> -->
                        <ul class="social">
                            <li><a routerLink="/Mechanism-Design-Details/{{item.id}}">{{ item.decription}}</a></li>
                            <!-- <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li> -->
                        </ul>
                    </div>
                </div>
            </div>
         </div>
    </div>
</div>
<!-- <a href="assets\img\arrow.png" download>download -->

