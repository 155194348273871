<app-header-style-two></app-header-style-two>
<div class="page-title-area page-title-style-three item-bg2">
    <div class="container">
      <div class="page-title-content">
              <ul>
                  <li>HUB</li>
                  <li>Links</li>
              </ul>
              <h2>Partners Links</h2>
          </div>
    </div>
  </div>

<div class="feedback-area ptb-100">
    <div class="container">
        <div class="feedback-slides">
            <!-- <owl-carousel-o [options]="feedbackSlides">

                <ng-template carouselSlide *ngFor="let item of data">
                    <div class="single-feedback-item">
                        <p>“{{item.Description}}”</p>
                        <div class="info">
                            <h3>{{item.Name}}</h3>
                            <a class="btn btn-primary" target="_blank" href="{{item.Url}}"><span style="color:white">Go TO Site</span> </a>
                            <img src="{{ BASE_URL.slice(0,-1) }}{{ item.logo }}" class="shadow rounded-circle" alt="logo">
                        </div>
                    </div>
                </ng-template>
             
            </owl-carousel-o> -->
       
       
            <div class="row" *ngFor="let item of data">
                <div class="block">
                    <img class="shadow img-thumbnail" src="{{ BASE_URL.slice(0,-1) }}{{ item.logo }}" alt="">
                  <div>
                    <h2>{{item.Name}}</h2>
                    <p>{{item.Description}}</p>
                  </div>
                  <a class="btn btn-primary" style="height: 30px; margin-top: 30px;" target="_blank" href="{{item.Url}}"><span style="color:white; font-size: initial;">Go to site</span> </a>
                </div>
              </div>
       
        </div>
    </div>
</div>


