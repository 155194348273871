import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mechanism-design-list',
  templateUrl: './mechanism-design-list.component.html',
  styleUrls: ['./mechanism-design-list.component.scss']
})
export class MechanismDesignListComponent implements OnInit {

  constructor() { }
  data=[
    {id :0 ,img:'assets/img/courses/courses1.jpg',title:'Raque Professional IT Expert Certificate Course',decription:'Raque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate Course'},
    {id :1 ,img:'assets/img/courses/courses1.jpg',title:'Raque Professional IT Expert Certificate Course',decription:'Raque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate Course'},
    {id :2 ,img:'assets/img/courses/courses1.jpg',title:'Raque Professional IT Expert Certificate Course',decription:'Raque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate Course'},
    {id :3 ,img:'assets/img/courses/courses1.jpg',title:'Raque Professional IT Expert Certificate Course',decription:'Raque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate Course'},
    {id :4 ,img:'assets/img/courses/courses1.jpg',title:'Raque Professional IT Expert Certificate Course',decription:'Raque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate Course'},
    {id :5 ,img:'assets/img/courses/courses1.jpg',title:'Raque Professional IT Expert Certificate Course',decription:'Raque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate Course'},
    {id :6 ,img:'assets/img/courses/courses1.jpg',title:'Raque Professional IT Expert Certificate Course',decription:'Raque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate Course'},
    {id :7 ,img:'assets/img/courses/courses1.jpg',title:'Raque Professional IT Expert Certificate Course',decription:'Raque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate Course'},
    {id :8 ,img:'assets/img/courses/courses1.jpg',title:'Raque Professional IT Expert Certificate Course',decription:'Raque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate Course'},
    {id :9 ,img:'assets/img/courses/courses1.jpg',title:'Raque Professional IT Expert Certificate Course',decription:'Raque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate Course'},
    {id :10 ,img:'assets/img/courses/courses1.jpg',title:'Raque Professional IT Expert Certificate Course',decription:'Raque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate Course'},
    {id :11 ,img:'assets/img/courses/courses1.jpg',title:'Raque Professional IT Expert Certificate Course',decription:'Raque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate Course'},
    {id :12 ,img:'assets/img/courses/courses1.jpg',title:'Raque Professional IT Expert Certificate Course',decription:'Raque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate CourseRaque Professional IT Expert Certificate Course'}]
    
  ngOnInit(): void {
  }

}
