<app-header-style-two></app-header-style-two>       


<div class="page-title-area page-title-style-three item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li>HUB</li>
                <li>Documents Repository</li>
            </ul>
            <h2>Documents Repository</h2>
        </div>
    </div>
</div>

<div style="margin-top: 10px;" >
    <div class="container">
        <div class="row">
            <a (click)="GetDocRepo(0)" style="margin-right: 2px; font-weight: bold; font-size: large;" class="col-lg-1 btn btn-success"> All </a>
            <a (click)="GetDocRepo(1)" style="margin-right: 2px; font-weight: bold; font-size: large;" class="col-lg-2 btn btn-warning"> Policy brief       </a>
            <a (click)="GetDocRepo(2)" style="margin-right: 2px; font-weight: bold; font-size: large;" class="col-lg-2 btn btn-warning"> Presentation 	   </a>
            <a (click)="GetDocRepo(3)" style="margin-right: 2px; font-weight: bold; font-size: large;" class="col-lg-2 btn btn-warning"> Book			   </a>
            <a (click)="GetDocRepo(4)" style="margin-right: 2px; font-weight: bold; font-size: large;" class="col-lg-2 btn btn-warning"> Poster			   </a>
            <a (click)="GetDocRepo(5)" style="margin-right: 2px; font-weight: bold; font-size: large;" class="col-lg-2 btn btn-warning"> Scientific article </a>
        </div>
    </div>
</div>
<!-- ptb-100 -->
<div class="instructor-area pt-100 pb-70" >
    <div class="container">

        <h1 style="color: gold; text-shadow: 1px 1px black;">Egypt Documents </h1>
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-4" *ngFor="let item of data|Filter:filterCasesEgypt">
                <div class="card">
                    <div class="card-details">
                      <p class="text-title">{{ item.Title}}</p>
                     
                      <div class="text-body">
                        <img src="assets/img/medium.png" alt="logo" width="100px"/>
                        <p>{{ item.Description}}</p>
                      </div>
                    </div>
                    <a class="card-button" href="{{Base_URL}}{{item.Url}}" >Download File</a>
                  </div>


  
            </div>
         </div>
         <hr>
         <h1 style="color: gold; text-shadow: 1px 1px black;">Greece Documents </h1>
         <div class="row">
             <div class="col-lg-3 col-md-4 col-sm-4" *ngFor="let item of data|Filter:filterCasesGreece">
                 <div class="card">
                     <div class="card-details">
                       <p class="text-title">{{ item.Title}}</p>
                      
                       <div class="text-body">
                         <img src="assets/img/medium.png" alt="logo" width="100px"/>
                         <p>{{ item.Description}}</p>
                       </div>
                     </div>
                     <a class="card-button" href="{{Base_URL}}{{item.Url}}" >Download File</a>
                   </div>
 
 
   
             </div>
          </div>
    </div>
</div>
<!-- <a href="assets\img\arrow.png" download>download -->

