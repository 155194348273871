<app-header-style-two></app-header-style-two>       


<div class="page-title-area page-title-style-three item-bg2">
    <div class="container">
        <div class="page-title-content" >
            <ul>
                <li>MEDIA CENTER</li>
                <li>Albums</li>
            </ul>
            <h2>Albums</h2>
        </div>
    </div>
</div>
<!-- ptb-100 -->
<div class="courses-area row " style="margin-top:20px; margin-bottom: 20px;"  >

    <div class="col-lg-3 col-md-3 col-sm-3 card"
    routerLink="/Albums-Details/{{item.Id}}"
    *ngFor="let item of Galleries" >

        <div class="home-slides card-body"  >
            <h2 class="card-title">{{item.Name}}</h2>
           
          <owl-carousel-o [options]="homeSlides"   >
            <ng-template carouselSlide *ngFor="let i of item.Images">

              <div  
                class="main-banner"
                [ngStyle]="{
                  'background-image': 'url(' + BASE_IMG_URL.slice(0,-1) + i.Url + ')',
                  'background-size':'cover',
                  'height': '300px'}">
              </div>
            </ng-template>
            <ng-template carouselSlide *ngFor="let iv of item.Videos">
                <video 
                style="width: 100% ; height: 100%; min-height: 300px; border: 8px #183e8d solid;padding: 3px;"
                layout-fill
                loop="loop"
                controls="true"
                preload="auto">
                <source src="{{BASE_IMG_URL.slice(0,-1) + iv.Url}}" type="video/mp4" />
              </video>
              </ng-template>


          </owl-carousel-o>
          <i style="color: darkgrey;">{{item.Date}}</i>
          <p class="card-text">{{item.Description}}</p>
        </div>
         <div>

         </div>
      </div>
    
</div>

