<app-header-style-two></app-header-style-two>      
<div class=" page-title-area page-title-style-three item-bg2">
  <div class="container">
    <div class="page-title-content">
            <ul>
                <li>HUB</li>
                <li>Business model canvas</li>
            </ul>
              <h2>Business model canvas</h2>          
        </div>      
  </div>
</div>
<div class="container" style="margin-top: 20px;">

    <mat-stepper orientation="vertical">
        <mat-step>
          <ng-template matStepLabel>1. Customer segments. </ng-template>
          <ng-template matStepContent>
            <div class="row mb-30">
              <p>List your most important (future) segments. Look for the segments that provide the most revenue.        </p>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Short text Customer segments</label><input class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.S_Customer_segments" type="text" placeholder="Enter short text Customer segments" /></div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Long text Customer segments</label><textarea  class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.Customer_segments" type="text" placeholder="Enter long text  Customer segments" ></textarea></div>
              </div>
            </div>
            
            <button class="btn btn-info" mat-button matStepperNext>Next</button>
          </ng-template>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>2. Value proposition.</ng-template>
          <ng-template matStepContent>
            <div class="row mb-30">
              <p>What are your products and services? What is the job you get done for your customer?              </p>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Short text Value proposition</label><input class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.S_Value_proposition" type="text" placeholder="Enter short text Value proposition" /></div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Long text Value proposition</label><textarea  class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.Value_proposition" type="text" placeholder="Enter long text  Value proposition" ></textarea></div>
              </div>
            </div>
            <button mat-button matStepperPrevious>Back</button>
            <button class="btn btn-info" mat-button matStepperNext>Next</button>
          </ng-template>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>3. Revenue streams.</ng-template>
          <ng-template matStepContent>
            <div class="row mb-30">
              <p>List your top three revenue streams. If you do things for free, add them here too.              </p>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Short text Revenue streams</label><input class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.S_Revenue_streams" type="text" placeholder="Enter short text Revenue streams" /></div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Long text Revenue streams</label><textarea  class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.Revenue_streams" type="text" placeholder="Enter long text  Revenue streams" ></textarea></div>
              </div>
            </div>
            <button mat-button matStepperPrevious>Back</button>
            <button class="btn btn-info" mat-button matStepperNext>Next</button>
          </ng-template>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>4. Societal and environmental benefits.</ng-template>
          <ng-template matStepContent>
            <div class="row mb-30">
              <p>What are you giving back to your community and planet? 
              </p>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Short text Societal and environmental benefits</label><input class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.S_Societal_and_environmental_benefits" type="text" placeholder="Enter short text Societal and environmental benefits" /></div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Long text Societal and environmental benefits</label><textarea  class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.Societal_and_environmental_benefits" type="text" placeholder="Enter long text  Societal and environmental benefits" ></textarea></div>
              </div>
            </div>
            <button mat-button matStepperPrevious>Back</button>
            <button class="btn btn-info" mat-button matStepperNext>Next</button>
          </ng-template>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>5. Channels.</ng-template>
          <ng-template matStepContent>
            <div class="row mb-30">
              <p>How do you communicate with your customer? How do you deliver the value proposition?              </p>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Short text Channels</label><input class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.S_Channels" type="text" placeholder="Enter short text Channels" /></div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Long text Channels</label><textarea  class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.Channels" type="text" placeholder="Enter long text  Channels" ></textarea></div>
              </div>
            </div>
            <button mat-button matStepperPrevious>Back</button>
            <button class="btn btn-info" mat-button matStepperNext>Next</button>
          </ng-template>
        </mat-step>


        <mat-step>
          <ng-template matStepLabel>6. Customer relationships.</ng-template>
          <ng-template matStepContent>
            <div class="row mb-30">
              <p>How does this show up and how do you maintain the relationship?</p>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Short text Customer relationships</label><input class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.S_Customer_relationships" type="text" placeholder="Enter short text Customer relationships" /></div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Long text Customer relationships</label><textarea  class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.Customer_relationships" type="text" placeholder="Enter long text  Customer relationships" ></textarea></div>
              </div>
            </div>
            <button mat-button matStepperPrevious>Back</button>
            <button class="btn btn-info" mat-button matStepperNext>Next</button>
          </ng-template>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>7. Key activities.</ng-template>
          <ng-template matStepContent>
            <div class="row mb-30">
              <p>What do you do every day to run your business model?              </p>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Short text Key activities</label><input class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.S_Key_activities" type="text" placeholder="Enter short text Key activities" /></div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Long text Key activities</label><textarea  class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.Key_activities" type="text" placeholder="Enter long text  Key activities" ></textarea></div>
              </div>
            </div>
            <button mat-button matStepperPrevious>Back</button>
            <button class="btn btn-info" mat-button matStepperNext>Next</button>
          </ng-template>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>8. Key resources.</ng-template>
          <ng-template matStepContent>
            <div class="row mb-30">
              <p>The people, knowledge, means, and money you need to run your business.              </p>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Short text Key resources</label><input class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.S_Key_resources" type="text" placeholder="Enter short text Key resources" /></div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Long text Key resources</label><textarea  class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.Key_resources" type="text" placeholder="Enter long text  Key resources" ></textarea></div>
              </div>
            </div>
            <button mat-button matStepperPrevious>Back</button>
            <button class="btn btn-info" mat-button matStepperNext>Next</button>
          </ng-template>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>9. Key partners.</ng-template>
          <ng-template matStepContent>
            <div class="row mb-30">
              <p>List the partners that you can’t do business without (not suppliers).              </p>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Short text Key partners</label><input class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.S_Key_partners" type="text" placeholder="Enter short text Key partners" /></div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Long text Key partners</label><textarea  class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.Key_partners" type="text" placeholder="Enter long text Key partners" ></textarea></div>
              </div>
            </div>
            <button mat-button matStepperPrevious>Back</button>
            <button class="btn btn-info" mat-button matStepperNext>Next</button>
          </ng-template>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>10. Cost structure.</ng-template>
          <ng-template matStepContent>
            <div class="row mb-30">
              <p>List your top costs by looking at activities and resources.              </p>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Short text Cost structure</label><input class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.S_Cost_structure" type="text" placeholder="Enter short text Cost structure" /></div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Long text Cost structure</label><textarea  class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.Cost_structure" type="text" placeholder="Enter long text  Cost structure" ></textarea></div>
              </div>
            </div>
            <button mat-button matStepperPrevious>Back</button>
            <button class="btn btn-info" mat-button matStepperNext>Next</button>
          </ng-template>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>11. Societal and environmental costs.</ng-template>
          <ng-template matStepContent>
            <div class="row mb-30">
              <p>What’s the negative impact of your business model?  </p>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Short text Societal and environmental costs</label><input class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.S_Societal_and_environmental_costs" type="text" placeholder="Enter short text Societal and environmental costs" /></div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Long text Societal and environmental costs</label><textarea  class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.Societal_and_environmental_costs" type="text" placeholder="Enter long text Societal and environmental costs" ></textarea></div>
              </div>
            </div>
            <button mat-button matStepperPrevious>Back</button>
            <button class="btn btn-info" mat-button matStepperNext>Next</button>
          </ng-template>
        </mat-step>


        <mat-step>
          <ng-template matStepLabel>Finish</ng-template>
          <ng-template matStepContent>
            <p>You Can Download Your Business model canvas </p>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button (click)="Save()" class="btn btn-primary" > Download your copy</button>
          </ng-template>
        </mat-step>

      </mat-stepper>


</div>