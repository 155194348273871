import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from "@angular/router";
import { OwlOptions } from 'ngx-owl-carousel-o';
import { WorkshopsService } from "src/app/services/workshops.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-workshope-details',
  templateUrl: './workshope-details.component.html',
  styleUrls: ['./workshope-details.component.scss']
})
export class WorkshopeDetailsComponent implements OnInit {

  
  constructor(private workshop: WorkshopsService, private route: ActivatedRoute,public http: HttpClient) { }
   id: number;
   data: any;
   dataAll: any[] = [];
   BASE_IMG_URL:string = environment.BASE_URL;
  ngOnInit(): void {
	this.route.paramMap.subscribe((params: ParamMap) => {
		this.id = +params.get('pid');		;
	  })
    this.workshop.getWorkshopByID(this.id).subscribe((data: any) =>{

      this.data = data})

	  this.http
	  .get(`${this.BASE_IMG_URL}Api/WorkShops/getAllWorkShops`)
	  .subscribe((dataAll: any) => (this.dataAll = dataAll));
  }
  homeSlides: OwlOptions = {
		loop: true,
		nav: true,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
		items: 1,
		autoHeight: true,
		navText: [
			"<i class='bx bx-chevron-left'></i>",
			"<i class='bx bx-chevron-right'></i>"
		]
    }


}
