<footer class="footer-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-footer-widget mb-30">
          <div class="logo">
            <a routerLink="/" class="d-inline-block"
              ><img
                src="assets/img/european_flag.jpg"
                alt="image"
                style="max-height: 50px"
            /></a>
          </div>
          <div class="newsletter-box">
            <p>
              The PRIMA programme is an Art. 185<br />
              initiative supported and funded under <br />
              Horizon 2020, the European Union's<br />
              Framework Programme for Research <br />
              and innovation
            </p>
          </div>
          <!-- <h3>Contact Us</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">2750 Tahrer - Cairo - Egypt</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+11234567890">+1 (123) 456 7890</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:info@SigmaNexus.com">info@SigmaNexus.com"</a>
                        </li>
                    </ul>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                    </ul> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-footer-widget mb-30">
          <div class="logo">
            <a routerLink="/" class="d-inline-block"
              ><img
                src="assets/img/prima_logo_v2.png"
                alt="image"
                style="max-height: 50px"
            /></a>
          </div>
          <div class="newsletter-box">
            <p>
              The Partnership for Research and<br />
              Innovation in the Mediterranean Area <br />
              will devise new R&I approaches to<br />
              improve water availability and <br />
              sustainable agriculture production in a region heavily distressed
              by climate<br />
              change. urbanisation and population<br />
              growth
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="single-footer-widget mb-30">
          <ul class="contact-us-link">
            <h3 >Beyond Sigma-Nexus</h3>
            <li>
              <a>A study to Songhai center of Porto Novo in Benin</a>
            </li>
            <li>
              <a
                >Working Together to Develop the Water. Energy and Food Security
                Sectors</a
              >
            </li>
            <li>
              <a
                >Tackling agricultural water pollution-a21st-century
                challenge</a
              >
            </li>
          </ul>
          <!-- <div class="newsletter-box">
                        <p>To get the latest news and latest updates from us.</p>
                        <form class="newsletter-form">
                            <label>Your e-mail address:</label>
                            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                            <button type="submit">Subscribe</button>
                        </form>
                    </div> -->
        </div>
      </div>
        <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Follow Us</h3>
                    <div class="footer-links social-md">
                    <div><a class="li" target="_blank" href="https://www.linkedin.com/in/sigma-nexus-project-7b985a231/"><i class="bx bxl-linkedin" aria-hidden="true"></i> Linked in</a></div>
                    <div><a class="tw" target="_blank" href="https://twitter.com/SigmaNexus"><i class="bx bxl-twitter" aria-hidden="true"></i> Twitter</a></div>
                    <div><a class="gi" target="_blank" href="https://www.youtube.com/@SIGMANexusProject"><i class="bx bxl-youtube" aria-hidden="true"></i> Youtube</a></div>
                    </div>

                   
                </div>
            </div> 
    </div>
  </div>
  <div class="footer-bottom-area">
    <div class="container">
      <p>
        <i class="bx bx-copyright"></i>2021
        <a routerLink="/" target="_blank"></a> Designed By
        <a href="https://smartec-systems.com/" target="_blank"
          >Smartec Systems</a
        >
        | All rights reserved.
      </p>
    </div>
  </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>
