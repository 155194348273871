export class BMC{
    constructor()
    {
        this.Id=0;
        this.Customer_segments="";
        this.Societal_and_environmental_costs="";
        this.Cost_structure="";
        this.Key_partners="";
        this.Key_resources="";
        this.Key_activities="";
        this.Customer_relationships="";
        this.Channels="";
        this.Societal_and_environmental_benefits="";
        this.Revenue_streams="";
        this.Value_proposition="";

        this.S_Customer_segments="";
        this.S_Societal_and_environmental_costs="";
        this.S_Cost_structure="";
        this.S_Key_partners="";
        this.S_Key_resources="";
        this.S_Key_activities="";
        this.S_Customer_relationships="";
        this.S_Channels="";
        this.S_Societal_and_environmental_benefits="";
        this.S_Revenue_streams="";
        this.S_Value_proposition="";
       
    }
    Id: number;
    Customer_segments: string;
    Societal_and_environmental_costs: string;
    Cost_structure: string;
    Key_partners: string;
    Key_resources: string;
    Key_activities: string;
    Customer_relationships: string;
    Channels: string;
    Societal_and_environmental_benefits: string;
    Revenue_streams: string;
    Value_proposition: string;

    S_Customer_segments: string;
    S_Societal_and_environmental_costs: string;
    S_Cost_structure: string;
    S_Key_partners: string;
    S_Key_resources: string;
    S_Key_activities: string;
    S_Customer_relationships: string;
    S_Channels: string;
    S_Societal_and_environmental_benefits: string;
    S_Revenue_streams: string;
    S_Value_proposition: string;
}