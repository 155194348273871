import { Component, OnInit } from '@angular/core';
import { CaseStudyService } from 'src/app/services/case-study.service';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-case-studies-list',
  templateUrl: './case-studies-list.component.html',
  styleUrls: ['./case-studies-list.component.scss'],
})
export class CaseStudiesListComponent implements OnInit {
  constructor(private caseStudy: CaseStudyService) {}
  data: any[] = [];
  BASE_URL = environment.BASE_URL;

  ngOnInit(): void {
    this.caseStudy.getAllCaseStudies().subscribe((data: any) => (this.data = data));
    
  }

  filterCasesEgypt(cases: any) {
    return cases.Country == "Egypt";
  }
  filterCasesGreece(cases: any) {
    return cases.Country == "Greece";
  }
}
