import { Component, OnInit } from '@angular/core';
import { WorkshopsService } from 'src/app/services/workshops.service';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-workshope-list',
  templateUrl: './workshope-list.component.html',
  styleUrls: ['./workshope-list.component.scss'],
})
export class WorkshopeListComponent implements OnInit {
  data: any[] = [];
  BASE_URL = environment.BASE_URL;
  constructor(private workshops: WorkshopsService) {}

  ngOnInit(): void {
    this.workshops
      .getAllWorkshops()
      .subscribe((data: any) => (this.data = data));
  }
}
