import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-game-redirect',
  templateUrl: './game-redirect.component.html',
  styleUrls: ['./game-redirect.component.scss']
})
export class GameRedirectComponent { //implements OnInit ,OnDestroy{
  UunityInstance: any;
  Play:boolean=false;
  constructor() { }

  ngOnDestroy(): void {
    //debugger;
    //this.UunityInstance.Quit();
  }

  // ngOnInit(): void {

  //   var buildUrl = "assets/WebGL/Build";
  //   var config = {
  //     dataUrl: buildUrl + "/WebGL.data",
  //     frameworkUrl: buildUrl + "/WebGL.framework.js",
  //     codeUrl: buildUrl + "/WebGL.wasm",
  //     streamingAssetsUrl: "StreamingAssets",
  //     companyName: "JoeMoceri",
  //     productName: "Unity Effects Pack",
  //     productVersion: "0.1",
  //     devicePixelRatio: 0
  //   };

  //   let container = document.querySelector("#unity-container") || new Element();
  //   var canvas : HTMLElement = document.querySelector("#unity-canvas") || new HTMLElement();
  //   var loadingBar : HTMLElement = document.querySelector("#unity-loading-bar") || new HTMLElement();
  //   var progressBarFull : HTMLElement = document.querySelector("#unity-progress-bar-full") || new HTMLElement();
  //   var fullscreenButton : HTMLElement = document.querySelector("#unity-fullscreen-button") || new HTMLElement();
  //   var warningBanner : HTMLElement = document.querySelector("#unity-warning") || new HTMLElement();


  //   if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
  //     container.className = "unity-mobile";
  //     config.devicePixelRatio = 1;
  //     warningBanner.style.display = "block";
  //     setTimeout(() => {
  //       warningBanner.style.display = "none";
  //     }, 5000);
  //   } else {
  //     canvas.style.width = "960px";
  //     canvas.style.height = "600px";
  //   }


  //   loadingBar.style.display = "block";

    

  //   createUnityInstance(canvas, config, (progress: any) => {
  //     progressBarFull.style.width = 100 * progress + "%";
  //   }).then((unityInstance: any) => {
  //     loadingBar.style.display = "none";
  //     fullscreenButton.onclick = () => {
  //       unityInstance.SetFullscreen(1);
  //       this.UunityInstance=unityInstance;
  //     };
  //   }).catch((message: any) => {
  //     alert(message);
  //   });

  

  // }

  playnow()
  {
    this.Play=true;
    
  }

 
}
