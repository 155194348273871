import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forum-redirect',
  templateUrl: './forum-redirect.component.html',
  styleUrls: ['./forum-redirect.component.scss']
})
export class ForumRedirectComponent implements OnInit {

  seconds:number=5;
  interval;
  constructor() { }

  ngOnInit(): void {
    this.interval = setInterval(() => {
      if (this.seconds === 0) {
        clearInterval(this.interval);
        
        window.location.href = 'https://forum.wecof-nexus.net/';
      } else {
        this.seconds--;
      }
    }, 1000);


  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

}
