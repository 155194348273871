<app-header-style-two></app-header-style-two>

<div class="page-title-area page-title-style-three item-bg2">
  <div class="container">
    <div class="page-title-content">
      <ul>
          <li>MEDIA CENTER</li>
          <li>Workshope</li>
      </ul>
      <h2>Workshope</h2>
  </div>
  </div>
</div>
<!-- ptb-100 -->
<div class="instructor-area pt-100 pb-70">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let item of data">
        <div class="single-instructor-member mb-30">
          <div
            routerLink="/Workshope-Details/{{ item.Id }}"
            class="member-image"
          >
            <img src="{{ BASE_URL.slice(0,-1) }}{{ item.ImageUrl }}" alt="images" />
          </div>
          <div class="member-content">
            <h3>
              <a routerLink="/Workshope-Details/{{ item.Id }}">{{
                item.Title
              }}</a>
            </h3>
            <!-- <span>OOP Developer</span> -->
            <ul class="social">
              <li>
                <a routerLink="/Workshope-Details/{{ item.Id }}">{{
                  item.ShortDescription
                }}</a>
              </li>
              <!-- <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container text-center">
  <div class="lds-facebook" *ngIf="!data.length">
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
<!-- <a href="assets\img\arrow.png" download>download -->
