<div class="home-slides">
  <owl-carousel-o [options]="homeSlides">
    <ng-template carouselSlide *ngFor="let item of data">
      <div
        class="main-banner"
        [ngStyle]="{
          'background-image': 'url(' + BASE_IMG_URL.slice(0,-1) + item.ImageUrl + ')'
        }"
      >
        <div class="d-table">
          <div class="d-table-cell">
            <div class="container">
              <div class="main-banner-content">
                <!-- <span class="sub-title">{{ item.Category_Name }}</span> -->
                <h1>{{ item.Title }}</h1>
                <p>{{ item.Description }}</p>
                <!-- <div class="btn-box">
                                    <a routerLink="/courses-2-columns-style-1" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View All</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                    <a routerLink="/contact" class="optional-btn">Get Started Free</a>
                                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- <ng-template carouselSlide>
            <div class="main-banner item-bg2">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content">
                                <span class="sub-title">The Future</span>
                                <h1>Transformative Courses for those who learn differently</h1>
                                <p> supports students by introducing collaborators outside R, internships and research experience abroad.</p>
                                <!-- <div class="btn-box">
                                    <a routerLink="/courses-2-columns-style-2" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View All</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                    <a routerLink="/contact" class="optional-btn">Get Started Free</a>
                                </div> -->
    <!-- </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner item-bg3">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content text-center">
                                <span class="sub-title">Aim for Excellence</span>
                                <h1>Learn a new skill from online courses</h1>
                                <p> supports students by introducing collaborators outside R, internships and research experience abroad.</p>
                                <!-- <div class="btn-box">
                                    <a routerLink="/courses-2-columns-style-3" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View All</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                    <a routerLink="/contact" class="optional-btn">Get Started Free</a>
                                </div> -->
    <!-- </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template> -->
  </owl-carousel-o>
</div>
