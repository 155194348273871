<app-header-style-two></app-header-style-two> 
<div class=" page-title-area page-title-style-three item-bg2">
    <div class="container">
      <div class="page-title-content">
              <ul>
                  <li>MEDIA CENTER </li>
                  <li>WorkShops</li>
              </ul>
                <h2>Workshope Details</h2>          
          </div>      
    </div>
  </div>
<div class="container" style="margin-top: 20px;">
    <div class="row">
        <div class="col-lg-8 col-md-8">
            
                <div class="home-slides">
                    <owl-carousel-o [options]="homeSlides">
                        <ng-template carouselSlide *ngFor="let item of data.S_WorkShops_Img">
                            <div class="main-banner" [ngStyle]="{
                                'background-image': 'url(' + BASE_IMG_URL.slice(0,-1) + item.Url + ')',
                                'background-size': 'contain',
                                'background-repeat': 'no-repeat'
                              }">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <div class="container">
                                            <div class="main-banner-content">
                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="blog-details-desc">
                            <div class="article-image">
                            </div>
                            <div class="article-content">
                                <h1>{{data?.Title}}</h1>
                                <p>{{data?.LongDescription}}</p>
                </div>
                </div>
    <div style="margin-top: 10px;">
    <h1>Attachmets</h1>
    <table class="table table-bordered table-hover" style="margin: 10px;">
        <tr><th>#</th><th>Title</th><th>Link</th></tr>
        <tr *ngFor="let excel of data?.S_WorkShops_Excel"><td>#</td><td>{{excel.Title}}</td><td><a target="_blank" href="{{BASE_IMG_URL.slice(0,-1)}}{{excel.Url}}"><img src="assets/img/excel.png" id="excel"  style="height: 36px;"></a></td></tr>
        <tr *ngFor="let pdf of data?.S_WorkShops_PDF"><td>#</td><td>{{pdf.Title}}</td><td><a target="_blank" href="{{BASE_IMG_URL.slice(0,-1)}}{{pdf.Url}}"><img src="assets/img/pdf (2).png" id="pdf" style="height: 36px;"></a></td></tr>
      </table>
    </div>
       
        </div>
        </div>
          
               
        </div>

    <div class="col-lg-4 col-md-4" >
        <ul class="list-group" >
            <li style="text-decoration: none; display: block; margin-bottom: 10px;"><h4  style="border: 1px blue solid; border-radius: 10px; background-color: #90ccef; padding: 5px;">Sigma Nexus WorkShops</h4></li>
            <li class="list-group-item " *ngFor="let item of dataAll">
              <h3><a href="/Workshope-Details/{{item.Id}}">{{item.Title}}</a></h3>  
              <p style="color: darkgray;">Date: {{item.Date.slice(0, data.Date.indexOf('T'))}}</p> 
            </li>
            
          </ul>
    </div>
    </div>
</div>


