import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-documents-repository-details',
  templateUrl: './documents-repository-details.component.html',
  styleUrls: ['./documents-repository-details.component.scss']
})
export class DocumentsRepositoryDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  homeSlides: OwlOptions = {
		loop: true,
		nav: true,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
		items: 1,
		autoHeight: true,
		navText: [
			"<i class='bx bx-chevron-left'></i>",
			"<i class='bx bx-chevron-right'></i>"
		]
    }
}
