import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { LinksService } from 'src/app/services/Links.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-partner-page',
  templateUrl: './partner-page.component.html',
  styleUrls: ['./partner-page.component.scss']
})
export class PartnerPageComponent implements OnInit {

	data:any;
	BASE_URL = environment.BASE_URL;
  constructor(private linksserv: LinksService) { }

  ngOnInit(): void {
	this.GetLinks();
  }
  feedbackSlides: OwlOptions = {
		loop: true,
		nav: true,
		dots: false,
		animateOut: 'fadeOut',
		autoplayHoverPause: true,
		autoplay: true,
		mouseDrag: false,
		items: 1,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		]
    }


	GetLinks()
	{
		this.linksserv.getAllLinks().subscribe((data: any) => (this.data = data));
	}
}
