import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Experiment } from '../Models/Experiment.model';

@Injectable({
  providedIn: 'root',
})
export class CaseStudyService {
  constructor(private http: HttpClient) {}

  getAllCaseStudies() {
    return this.http.get<any>(
      `${environment.BASE_URL}/Api/CaseStudies/getAllCaseStudies`
    );
  }
  getSigmaCaseStudies() {
    return this.http.get<any>(
      `${environment.BASE_URL}/Api/CaseStudies/getSigmaCaseStudies`
    );
  }
  getOtherCaseStudies() {
    return this.http.get<any>(
      `${environment.BASE_URL}/Api/CaseStudies/getOtherCaseStudies`
    );
  }

  getCaseStudyByID(id: number) {
    return this.http.get<any>(
      `${environment.BASE_URL}/Api/CaseStudies/${id}`
    );
  }
  getChartByID(id: number) {
    return this.http.get<any>(
      `${environment.BASE_URL}/Api/Chart/${id}`
    );
  }
  getCaseStudyExperimentByID(id: number) {
    return this.http.get<Experiment>(
      `${environment.BASE_URL}/Api/CaseStudiesExperiment/${id}`
    );
  }
}
