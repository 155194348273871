import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-boxes',
  templateUrl: './boxes.component.html',
  styleUrls: ['./boxes.component.scss'],
})
export class BoxesComponent implements OnInit {
  data: any[] = [];
  BASE_URL = environment.BASE_URL;
  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.http
      .get(`${this.BASE_URL}Api/Topics/GetTopTopic/4`)
      .subscribe((data: any) => (this.data = data));
  }
}
