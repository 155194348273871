<div class="courses-categories-slides">
  <owl-carousel-o [options]="categoriesSlides">
    <ng-template carouselSlide *ngFor="let item of data">
      <div class="single-categories-courses-box mb-30">
        <div class="icon">
          <i class="bx bx-code-alt"></i>
        </div>
        <h3>{{ item.Title }}</h3>
        <span>{{ item.body }}</span>
        <a routerLink="/" class="link-btn"></a>
      </div>
    </ng-template>
    <!-- <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class='bx bx-camera'></i>
                </div>
                <h3>Photography </h3>
                <span>21 Courses</span>
                <a routerLink="/" class="link-btn"></a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class='bx bx-layer'></i>
                </div>
                <h3>Graphics Design</h3>
                <span>58 Courses</span>
                <a routerLink="/" class="link-btn"></a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class='bx bxs-flag-checkered'></i>
                </div>
                <h3>Web Language</h3>
                <span>99 Courses</span>
                <a routerLink="/" class="link-btn"></a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class='bx bx-health'></i>
                </div>
                <h3>Health & Fitness</h3>
                <span>21 Courses</span>
                <a routerLink="/" class="link-btn"></a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class='bx bx-line-chart'></i>
                </div>
                <h3>Business Studies</h3>
                <span>49 Courses</span>
                <a routerLink="/" class="link-btn"></a>
            </div>
        </ng-template> -->
  </owl-carousel-o>
</div>
