<app-header-style-two></app-header-style-two>

<div class="page-title-area page-title-style-three item-bg2">
  <div class="container">
    <div class="page-title-content">
      <ul>
          <li>MEDIA CENTER</li>
          <li>Webinars</li>
      </ul>
      <h2> Webinars</h2>
  </div>
  </div>
</div>
<!-- ptb-100 -->
<div class="courses-area" style="margin-top: 20px">
  <!-- <div class="container">
        <div class="courses-topbar">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-4">
                    <div class="topbar-result-count">
                        <p>Showing 1 – 6 of 54</p>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <div class="topbar-ordering-and-search">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-5 offset-lg-4 offset-md-1 col-sm-6">
                                <div class="topbar-ordering">
                                    <select class="form-select">
                                        <option>Sort by popularity</option>
                                        <option>Sort by latest</option>
                                        <option>Default sorting</option>
                                        <option>Sort by rating</option>
                                        <option>Sort by new</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6 col-sm-6">
                                <div class="topbar-search">
                                    <form>
                                        <label><i class="bx bx-search"></i></label>
                                        <input type="text" class="input-search" placeholder="Search here...">
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3 col-md-6" *ngFor="let item of data">
        <div class="single-courses-box mb-30">
          <div class="courses-image">
            <a
              routerLink="/Webinars-Details/{{ item.Id }}"
              class="d-block"
              ><img src="{{ BASE_URL.slice(0,-1) }}{{ item.ImageUrl }}" alt="image"
            /></a>
            <!-- <div class="courses-tag">
                            <a routerLink="/courses-4-columns-style-1" class="d-block">Business</a>
                        </div> -->
          </div>
          <div class="courses-content">
            <!-- <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Steven Smith</span>
                        </div> -->
            <h3>
              <a
                routerLink="/Webinars-Details/{{ item.Id }}"
                class="d-inline-block"
                >{{ item.Title }}</a
              >
            </h3>
            <!-- <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <div class="rating-total">
                                5.0 (1 rating)
                            </div>
                        </div> -->
          </div>
          <div
            class="courses-box-footer"
            routerLink="/Webinars-Details/{{ item.Id }}"
          >
            <a routerLink="/Webinars-Details/{{ item.Id }}">
              <ul>
                <li class="students-number"></li>
                <li class="courses-lesson">
                  <i class="bx bx-book-open"></i> More Info
                </li>
                <li class="courses-price"></li>
              </ul>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container text-center">
    <!-- <div class="lds-facebook" *ngIf="data.length>0">
      <div></div>
      <div></div>
      <div></div>
    </div> -->
  </div>
</div>
