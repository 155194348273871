import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'empty-page',
    templateUrl: 'empty-page.component.html',
    styleUrls: ['empty-page.component.scss']
})
export class EmptyPageComponent implements OnInit {
    pageTitle:string = '';
    constructor(
        private route:ActivatedRoute,
    ) {

     }
  
    ngOnInit(): void {
        this.pageTitle =  this.route.snapshot.paramMap.get('pageTitle');
    }
  
  }