import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-hometwo-main-banner',
  templateUrl: './hometwo-main-banner.component.html',
  styleUrls: ['./hometwo-main-banner.component.scss'],
})
export class HometwoMainBannerComponent implements OnInit {
  data: any[] = [];
  BASE_IMG_URL = environment.BASE_URL;
  constructor(public http: HttpClient) {
    this.http
      .get(`${this.BASE_IMG_URL}Api/Sliders/getAllSliders`)
      .subscribe((data: any) => (this.data = data));
  }

  ngOnInit(): void {}

  homeSlides: OwlOptions = {
    loop: true,
    nav: true,
    dots: true,
    autoplayHoverPause: true,
    autoplay: true,
    items: 1,
    autoHeight: true,
    navText: [
      "<i class='bx bx-chevron-left'></i>",
      "<i class='bx bx-chevron-right'></i>",
    ],
  };
}
