<app-header-style-two></app-header-style-two>       


<div class="page-title-area page-title-style-three item-bg2">
    <div class="container">
        <!-- <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Courses</li>
            </ul>
            <h2>Courses 4 Columns</h2>
        </div> -->
    </div>
</div>
<!-- ptb-100 -->
<div class="courses-area " style="margin-top: 20px;"  >
   
    <div >
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let item of data">
                <div class="single-courses-box mb-30  card list-view-media" style="padding: 10px; height: 210px;">
                  <div class="row " >
                    <div class="col-md-5">
                     <a class="media-left ripple light" routerLink="/Case-Studies-Details/{{item.id}}" href="javascript:">
                         <img class="media-object" src="{{item.img}}" alt="Generic placeholder image">
                       </a>
                    </div>
                    <div class="col-md-7">
                     <div class="media-body">  
                         <div class="col-xs-12">
                           <h6 class="d-inline-block" >
                             <a  routerLink="/Case-Studies-Details/{{item.id}}">
                             {{item.title}}</a>
                            </h6>
                         </div>
                  
                         <p  style="max-height: 105px;overflow: hidden;" >{{item.decription}}</p>
                           <button  routerLink="/Case-Studies-Details/{{item.id}}">More info ....</button>
     
                        
                       </div>
                    </div>
            
                    </div>
                </div>
            </div>
            </div>
    </div>
