<app-header-style-two></app-header-style-two>   

<div class="home-slides card-body" style="margin-top:120px; margin-bottom: 50px; margin-left: 20px; margin-right: 20px;" >
    <h2 class="card-title">{{item.Name}}</h2>
   
  <owl-carousel-o [options]="homeSlides"   >
    <ng-template carouselSlide *ngFor="let i of item.Images">

      <div
        class="main-banner"
        [ngStyle]="{
          'background-image': 'url(' + BASE_IMG_URL.slice(0,-1) + i.Url + ')',
          'background-size':'cover',
          'height': '600px'}">
      </div>
    </ng-template>
    <ng-template carouselSlide *ngFor="let iv of item.Videos">
        <video 
        style="width: 100% ; height: 100%; min-height: 600px; border: 8px #183e8d solid;padding: 3px;"
        layout-fill
        loop="loop"
        controls="true"
        preload="auto">
        <source src="{{BASE_IMG_URL.slice(0,-1) + iv.Url}}" type="video/mp4" />
      </video>
      </ng-template>


  </owl-carousel-o> 

 


  <i style="color: darkgrey;">{{item.Date}}</i>
  <p class="card-text">{{item.Description}}</p>
</div>


