import { Component, OnInit } from '@angular/core';
import { DocumentsRepoService } from "src/app/services/documents-repo.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-documents-repository-list',
  templateUrl: './documents-repository-list.component.html',
  styleUrls: ['./documents-repository-list.component.scss']
})
export class DocumentsRepositoryListComponent implements OnInit {

  constructor(private documents: DocumentsRepoService) { }
  data: any;
  Base_URL: string = environment.BASE_URL; 
  ngOnInit(): void {
    this.documents.getAllDocuments().subscribe((data: any) => this.data = data)
  }


  GetDocRepo(type:any)
  {
    this.documents.getDocumentsRepobyType(type).subscribe((data: any) => this.data = data)
  }

  filterCasesEgypt(documents: any) {
    return documents.Country == "Egypt";
  }
  filterCasesGreece(documents: any) {
    return documents.Country == "Greece";
  }
}
