<app-header-style-two></app-header-style-two>

<div class=" page-title-area page-title-style-three item-bg2">
  <div class="container">
    <div class="page-title-content" > 
            <ul>
                <li>HUB</li>
                <li>Case Studies</li>
            </ul>
              <h2 >Case Studies</h2>          
        </div>      
  </div>
</div>

<div class="courses-area" style="margin-top: 20px ">
  <div class="container">
    <div class="row">
      <h1 style="color: gold; text-shadow: 1px 1px black;">Egypt </h1>
    </div>
    <div class="courses-area" style="margin-top: 20px">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-6"  *ngFor="let item of data |Filter:filterCasesEgypt">
            <div class="single-testimonials-item" routerLink="/Case-Studies-Details/{{ item.Id }}">
              <p>{{ item.ShortDescription }}</p>
              <div class="info">
    
                <img
                  src="{{ BASE_URL.slice(0,-1) }}{{ item.ImageUrl }}"
                  class="shadow rounded-circle"
                  alt="image"/>
    
                <h3>{{ item.Title }}</h3>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    
  </div>

  <div class="courses-area" style="margin-top: 20px">
    <div class="container">
      <div class="row">
        <h1 style="color: gold; text-shadow: 1px 1px black;">Greece</h1>
      </div>
      <div class="courses-area" style="margin-top: 20px">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6"  *ngFor="let item of data |Filter:filterCasesGreece">
              <div class="single-testimonials-item" routerLink="/Case-Studies-Details/{{ item.Id }}">
                <p>{{ item.ShortDescription }}</p>
                <div class="info">
      
                  <img
                    src="{{ BASE_URL.slice(0,-1) }}{{ item.ImageUrl }}"
                    class="shadow rounded-circle"
                    alt="image"/>
      
                  <h3>{{ item.Title }}</h3>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      
    </div>


<!-- <div class="courses-area" style="margin-top: 20px">
  <div class="container">
    <div class="row" *ngIf="data?.length > 0">
    <h1 style="color: gold; text-shadow: 1px 1px black;">Sigma-Nexus Case Studies</h1>
      
    
      <div class="col-lg-6 col-md-6"  *ngFor="let item of data">
        <div class="single-testimonials-item" routerLink="/Case-Studies-Details/{{ item.Id }}">
          <p>{{ item.ShortDescription }}</p>
          <div class="info">

            <img
              src="{{ BASE_URL.slice(0,-1) }}{{ item.ImageUrl }}"
              class="shadow rounded-circle"
              alt="image"/>

            <h3>{{ item.Title }}</h3>
            <span></span>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>


<div class="courses-area" style="margin-top: 20px">
  <div class="container">
    <div class="row" *ngIf="dataother?.length > 0">
      <h1 style="color: gold; text-shadow: 1px 1px black;">Other Nexus Case Studies</h1>
      <div class="col-lg-6 col-md-6" *ngFor="let item of dataother">
        <div
          class="single-courses-box mb-30 card list-view-media"
          style="padding: 10px; height: 210px"
        >
          <div class="row">
            <div class="col-md-5">
              <a
                class="media-left ripple light"
                routerLink="/Case-Studies-Details/{{ item.Id }}"
                href="javascript:"
              >
                <img
                  class="media-object"
                  src="{{ BASE_URL.slice(0,-1) }}{{ item.ImageUrl }}"
                  alt="Generic placeholder image"
                />
              </a>
            </div>
            <div class="col-md-7">
              <div class="media-body">
                <div class="col-xs-12">
                  <h6 class="d-inline-block">
                    <a routerLink="/Case-Studies-Details/{{ item.Id }}">
                      {{ item.title }}</a
                    >
                  </h6>
                </div>

                <p style="max-height: 105px; overflow: hidden">
                  {{ item.ShortDescription }}
                </p>
                <button routerLink="/Case-Studies-Details/{{ item.Id }}">
                  More info ....
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->