<app-header-style-two></app-header-style-two>   
<div class="container" style="margin-top: 120px;">
    <h1 >{{data.Name}}</h1>
    <div class="row">
    <div class="col-lg-6">
      <h1 >Images</h1>
      <div class="home-slides" style="border: 2px solid black;" >
        <owl-carousel-o [options]="homeSlides"  >
            <ng-container *ngFor="let item of data.S_CaseStudiesExperiments_Img">
              <ng-template carouselSlide >
                <img   style="height: 600px;"  [src]="BASE_IMG_URL.slice(0,-1) + item.Url"  [title]="item.Title">
              </ng-template>
            </ng-container>
        
          </owl-carousel-o>
    </div>
    </div>
    <div class="col-lg-6">
      <h1 >Videos</h1>
      <owl-carousel-o [options]="homeSlides" >
          <ng-template carouselSlide *ngFor="let video of data.S_CaseStudiesExperiments_Video">
              <video width="320" height="400" controls>
                  <source src='{{BASE_IMG_URL.slice(0,-1)}}{{video.Url}}'>
                </video>
          </ng-template>
        
      </owl-carousel-o>
    </div>
    </div>



   <h1>Attachments</h1>
   <div *ngFor="let f of data.S_CaseStudiesExperimentsFiles">
        <h3>{{f.Title}}</h3>
        <a target="_blank" href="{{BASE_IMG_URL.slice(0,-1)}}{{f.Url}}">{{f.Title}}</a>
   </div>

</div>
