import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DissemenationmaterialService } from 'src/app/services/Dissemenationmaterial.service';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-dissemenationmaterial-list',
  templateUrl: './dissemenationmaterial-list.component.html',
  styleUrls: ['./dissemenationmaterial-list.component.scss']
})
export class DissemenationmaterialListComponent implements OnInit {

  constructor(private documents: DissemenationmaterialService,private sanitization:DomSanitizer) { }
  data: any;
  Base_URL: string = environment.BASE_URL; 
  ngOnInit(): void {
    this.documents.getAllDocuments().subscribe((data: any) => this.data = data)
  }

  filterCasesEgypt(documents: any) {
    return documents.Country == "Egypt";
  }
  filterCasesGreece(documents: any) {
    return documents.Country == "Greece";
  }

  getlink(link:any):SafeResourceUrl
	{
     if(String(link).includes("http"))
	 {
          return this.sanitization.bypassSecurityTrustResourceUrl(link);
	 }
	 else
	 {
	    return this.sanitization.bypassSecurityTrustResourceUrl(this.Base_URL.slice(0,-1)+link);
	 }

	}
}
