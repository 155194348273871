import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from "@angular/router";
import { OwlOptions } from 'ngx-owl-carousel-o';
import { WebinarsService } from "src/app/services/webinars.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-webinars-details',
  templateUrl: './webinars-details.component.html',
  styleUrls: ['./webinars-details.component.scss']
})
export class WebinarsDetailsComponent implements OnInit {

 
  constructor(private webinar: WebinarsService, private route: ActivatedRoute) { }
   id: number;
   data: any;
   BASE_IMG_URL:string = environment.BASE_URL;
  ngOnInit(): void {
	this.route.paramMap.subscribe((params: ParamMap) => {
		this.id = +params.get('pid');		;
	  })
    this.webinar.getWebinarByID(this.id).subscribe((data: any) => this.data = data)
  }
  homeSlides: OwlOptions = {
		loop: true,
		nav: true,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
		items: 1,
		autoHeight: true,
		navText: [
			"<i class='bx bx-chevron-left'></i>",
			"<i class='bx bx-chevron-right'></i>"
		]
    }


}
