<div class="row">
  <div class="col-lg-3 col-md-3 col-6">
    <div class="single-funfact">
      <div class="icon">
        <i class="bx bxs-group"></i>
      </div>
      <h3>
        <span [countUp]="UserCount">{{ UserCount }}</span>
      </h3>
      <p>User</p>
    </div>
  </div>
  <div class="col-lg-3 col-md-3 col-6">
    <div class="single-funfact">
      <div class="icon">
        <i class="bx bx-book-reader"></i>
      </div>
      <h3>
        <span [countUp]="WebinarsCount">{{ WebinarsCount }}</span>
      </h3>
      <p>Webinars</p>
    </div>
  </div>
  <div class="col-lg-3 col-md-3 col-6">
    <div class="single-funfact">
      <div class="icon">
        <i class="bx bx-user-pin"></i>
      </div>
      <h3>
        <span [countUp]="CaseStudiesCount">{{ CaseStudiesCount }}</span>
      </h3>
      <p>Case Studies</p>
    </div>
  </div>
  <div class="col-lg-3 col-md-3 col-6">
    <div class="single-funfact">
      <div class="icon">
        <i class="bx bxl-deviantart"></i>
      </div>
      <h3>
        <span [countUp]="DocumentsRepoCount">{{ DocumentsRepoCount }}</span>
      </h3>
      <p>Documents Repo</p>
    </div>
  </div>
</div>
