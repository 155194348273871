<div class=" row">
  <!-- <owl-carousel-o [options]="testimonialsSlides"> -->
   
    <!-- <ng-template carouselSlide *ngFor="let item of data">
      <div class="single-testimonials-item" routerLink="/Case-Studies-Details/{{ item.Id }}">
        <p>{{ item.ShortDescription }}</p>
        <div class="info">
          <img
            src="{{ BASE_URL.slice(0,-1) }}{{ item.ImageUrl }}"
            class="shadow rounded-circle"
            alt="image"
          />
          <h3>{{ item.Title }}</h3>
          <span></span>
        </div>
      </div>
    </ng-template> -->

    <div class="col-lg-4"  *ngFor="let item of data">
    <article class="card" routerLink="/Case-Studies-Details/{{ item.Id }}">
      <div class="temporary_text">
        <img class="rounded" src="{{ BASE_URL.slice(0,-1) }}{{ item.ImageUrl }}" alt="image"/>
      </div>
    
  <div class="card_content">
      <span class="card_title">{{ item.Title }}</span>
          <span class="card_subtitle">{{ item.ShortDescription }}</span>
          <p class="card_description"></p>
      
  </div>
 
  </article>
</div>

    <!-- <ng-template carouselSlide>
            <div class="single-testimonials-item">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                <div class="info">
                    <img src="assets/img/user2.jpg" class="shadow rounded-circle" alt="image">
                    <h3>Sarah Taylor</h3>
                    <span>Student</span>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-testimonials-item">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                <div class="info">
                    <img src="assets/img/user3.jpg" class="shadow rounded-circle" alt="image">
                    <h3>David Warner</h3>
                    <span>Student</span>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-testimonials-item">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                <div class="info">
                    <img src="assets/img/user4.jpg" class="shadow rounded-circle" alt="image">
                    <h3>James Anderson</h3>
                    <span>Student</span>
                </div>
            </div>
        </ng-template> -->
  <!-- </owl-carousel-o> -->
</div>
