import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Experiment } from '../Models/Experiment.model';

@Injectable({
    providedIn: 'root',
  })
  export class LinksService {
    constructor(private http: HttpClient) {}

    getAllLinks() {
        return this.http.get<any>(
          `${environment.BASE_URL}/Api/Links/getAllLinks`
        );
      }

  }