<div class="blog-slides">
  <owl-carousel-o [options]="blogSlides">
    <ng-template carouselSlide *ngFor="let item of data">
      <div class="single-blog-post mb-30">
        <div class="post-image">
          <a routerLink="/single-blog/{{item.Id}}" class="d-block">
            <img src="{{ BASE_URL.slice(0,-1) }}{{ item.ImageUrl }}" style="height: 400px !important;" width="100%" alt="image" />
          </a>
          <!-- <div class="tag">
                        <a routerLink="/">Learning</a>
                    </div> -->
        </div>
        <div class="post-content">
          <ul class="post-meta">
       
            <li>
              <a routerLink="/single-blog/{{item.Id}}">{{
                item.Date.slice(0, item.Date.indexOf('T'))
              }}</a>
            </li>
          </ul>
          <h3>
            <a routerLink="/single-blog/{{item.Id}}" class="d-inline-block">{{
              item.Title
            }}</a>
          </h3>
          <a routerLink="/single-blog/{{item.Id}}" class="read-more-btn"
            >Read More <i class="bx bx-right-arrow-alt"></i
          ></a>
        </div>
      </div>
    </ng-template>


  
    </owl-carousel-o>
</div>
