import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    location: any;
    routerSubscription: any;

    constructor(private router: Router,private http: HttpClient) {
        
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
              this.trackVisitor(event.urlAfterRedirects);
            }
          });
    }

    trackVisitor(pageUrl: string) {
        const visitorData = {
          url: pageUrl,
          timestamp: new Date().toISOString(),
          userAgent: navigator.userAgent,  // Browser details
          platform: navigator.platform,    // OS details
          language: navigator.language,    // Browser language
        };

       
        this.http.post(environment.BASE_URL+'api/visitors', visitorData)
        .subscribe(response => {
            console.log('Visitor data sent successfully', response);
          }, error => {
            console.error('Error sending visitor data', error);
          });
      }
    

    ngOnInit(){
        this.recallJsFuntions();
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
}