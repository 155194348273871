import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-feedback-style-two',
  templateUrl: './feedback-style-two.component.html',
  styleUrls: ['./feedback-style-two.component.scss'],
})
export class FeedbackStyleTwoComponent implements OnInit {
  data: any[] = [];
  BASE_URL: string = environment.BASE_URL;
  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.http
      .get(`${this.BASE_URL}Api/CaseStudies/GetTopCaseStudies/4`)
      .subscribe((data: any) => (this.data = data));
  }

  testimonialsSlides: OwlOptions = {
    loop: true,
    nav: false,
    dots: true,
    autoplayHoverPause: true,
    autoplay: true,
    center: true,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      768: {
        items: 2,
      },
      1200: {
        items: 3,
      },
    },
  };
}
