<app-header-style-two></app-header-style-two>       
<div class="container">
    <div class="home-slides">
        <owl-carousel-o [options]="homeSlides" >
            <ng-template carouselSlide *ngFor="let item of data.S_CaseStudies_Img">
                <div class="main-banner" [ngStyle]="{
                    'background-image': 'url(' + BASE_IMG_URL.slice(0,-1) + item.Url + ')'
                  }">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="main-banner-content">
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>
<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                    </div>
                    <div class="article-content">
                        <h3>{{data.Title}}</h3>
                        <p>{{data.LongDescription}}</p>
        </div>
        </div>
        <div class="row" *ngIf="data.S_Webinars_Excel.length !== 0">
            <h2>Excel Links</h2>
            <div *ngFor="let excel of data.S_Webinars_Excel">
                 <h3>{{excel.Title}}</h3>
                 <a target="_blank" href="{{BASE_IMG_URL.slice(0,-1)}}{{excel.Url}}">Excel Link</a>
            </div>
        </div>
        <div class="row" *ngIf="data.S_Webinars_PDF.length !== 0">
            <h2>PDF Links</h2>
            <div *ngFor="let pdf of data.S_Webinars_PDF">
                <h3>{{pdf.Title}}</h3>
                <a target="_blank" href="{{BASE_IMG_URL.slice(0,-1)}}{{pdf.Url}}">PDF Link</a>
            </div>
        </div>
</div>
</div>
