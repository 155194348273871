<div class="students-feedback-form-area ptb-20" style="padding: 20px;">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="students-feedback-form-content">
          <h2>We achieve great things.</h2>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="students-feedback-form">
          <h3>Feedback Form</h3>
          <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="First name*"
                    formControlName="Name"
                  />
                  <span class="label-title"><i class="bx bx-user"></i></span>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Last Name"
                    formControlName="LastName"
                  />
                  <span class="label-title"><i class="bx bx-user"></i></span>
                </div>
              </div>
              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Your email*"
                    formControlName="Email"
                  />
                  <span class="label-title"
                    ><i class="bx bx-envelope"></i
                  ></span>
                </div>
              </div>
              
              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <textarea
                    cols="30"
                    rows="3"
                    class="form-control"
                    placeholder="Write something here"
                    formControlName="Message"
                  ></textarea>
                  <span class="label-title"><i class="bx bx-edit"></i></span>
                </div>
              </div>
              <div class="col-lg-12 col-md-12">
                <button type="submit" class="default-btn">
                  <i class="bx bx-paper-plane icon-arrow before"></i
                  ><span class="label">Send Message</span
                  ><i class="bx bx-paper-plane icon-arrow after"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
