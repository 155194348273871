import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NewsService } from "src/app/services/news.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-blog-details-page',
  templateUrl: './blog-details-page.component.html',
  styleUrls: ['./blog-details-page.component.scss']
})
export class BlogDetailsPageComponent implements OnInit {

  id:number = 0;
  data: any;

  dataAll: any[] = [];
  BASE_URL = environment.BASE_URL;

  constructor( private route:ActivatedRoute, private news: NewsService,public http: HttpClient ,private sanitizer: DomSanitizer) {
    this.id =  +this.route.snapshot.paramMap.get('id');

   }
   Base_url=environment.BASE_URL;
  ngOnInit(): void {
    this.news.getNewByID(this.id).subscribe(data => {
this.data = data;
    })

    this.http
    .get(`${this.BASE_URL}Api/News/getAllNews`)
    .subscribe((dataAll: any) => (this.dataAll = dataAll));


  }

  splitDescription(theString: string) {
    return theString.split(',');
}

transform(url) {
  return this.sanitizer.bypassSecurityTrustResourceUrl(url);
}
}
