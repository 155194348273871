import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Experiment } from 'src/app/Models/Experiment.model';
import { CaseStudyService } from 'src/app/services/case-study.service';
import { environment } from 'src/environments/environment';
import { ChartOptions } from 'chart.js';

@Component({
  selector: 'app-experiment-details',
  templateUrl: './experiment-details.component.html',
  styleUrls: ['./experiment-details.component.scss']
})
export class ExperimentDetailsComponent implements OnInit {

  id: number;
  data: Experiment;
  BASE_IMG_URL:string = environment.BASE_URL;






  constructor(private route: ActivatedRoute,private caseStudy: CaseStudyService ) 
  {
    this.data=new Experiment();
  }

  ngOnInit(): void {

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = +params.get('pid');		;
      })

      debugger;
    this.caseStudy.getCaseStudyExperimentByID(this.id).subscribe(
      d => {
        if(d!=null)
        this.data = d;

        console.log(d);
      },
      error => {
      });
    

  }


  homeSlides: OwlOptions = {
		loop: true,
		nav: true,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
		items: 1,
		autoHeight: true,
		navText: [
			"<i class='bx bx-chevron-left'></i>",
			"<i class='bx bx-chevron-right'></i>"
		]
    }
}
