import { Component, OnInit } from '@angular/core';
import { VPC } from '../Models/VPC.model';
import { publicService } from '../services/public-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-vpc',
  templateUrl: './vpc.component.html',
  styleUrls: ['./vpc.component.scss']
})
export class VPCComponent implements OnInit {
  mainObject:VPC;
  constructor(public service: publicService) 
  {
     this.mainObject=new VPC();
  }

  ngOnInit(): void {
  }

Save()
{
  this.service.post(this.mainObject, "Api/VPC", "").subscribe(
    (res) => {
      this.mainObject=res;

      this.service.get("Api/VPC/print/"+this.mainObject.Id, "","").subscribe(
        (rep) => {

          window.location.href = environment.BASE_URL+""+rep.pdfUrl;
        

        },
        (err) => { 
    
        })
      
    },
    (err) => { 

    })

}
}

