import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private http: HttpClient) { }

  getNewByID(id: number) {
    return this.http.get<any>(`${environment.BASE_URL}/Api/News/${id}`)
  }
}
