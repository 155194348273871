<div class="offer-area pt-100">
  <div class="container">
    <div class="section-title">
      <h2>Workshops for Professional</h2>
    </div>

    <!-- <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6" *ngIf="upcommingWorkShops?.length > 0">
        <div class="home-slides">
          <owl-carousel-o [options]="homeSlides">
            <ng-template carouselSlide *ngFor="let item of upcommingWorkShops">
              <div
              routerLink="/Workshope-Details/{{item.Id}}"
                class="main-banner"
                [ngStyle]="{
                  'background-image': 'url(' + BASE_IMG_URL.slice(0,-1) + item.ImageUrl + ')',
                  'height': '300px'
                }" >
                <div class="d-table">
                  <div class="d-table-cell">
                    <div class="container">
                      <div class="main-banner-content">
                        <span class="sub-title">{{ item.Category_Name }}</span>
                        <h1>{{ item.Title }}</h1>
                        <p>{{ item.Description }}</p>
                     
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            
          </owl-carousel-o>
        </div>
       
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6" style="background-color: bisque; border: 1px #000000 solid;" *ngIf="upcommingWorkShops?.length == 0">
        <div class="home-slides">
          <h4>No Upcomming WorkShops</h4>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6" *ngIf="PreviousWorkShops?.length > 0">
        <div class="home-slides">
          <owl-carousel-o [options]="homeSlides">
            <ng-template carouselSlide *ngFor="let item of PreviousWorkShops">
              <div
              routerLink="/Workshope-Details/{{item.Id}}"
                class="main-banner"
                [ngStyle]="{
                  'background-image': 'url(' + BASE_IMG_URL.slice(0,-1) + item.ImageUrl + ')',
                  'height': '300px'
                }"
              >
                <div class="d-table">
                  <div class="d-table-cell">
                    <div class="container">
                      <div class="main-banner-content">
                        <span class="sub-title">{{ item.Category_Name }}</span>
                        <h1>{{ item.Title }}</h1>
                        <p>{{ item.Description }}</p>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6" style="background-color: bisque; border: 1px #000000 solid;" *ngIf="PreviousWorkShops?.length == 0">
        <div class="home-slides">
          <h4>No Previous WorkShops</h4>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6" *ngIf="upcommingWebinars?.length > 0">
        <div class="home-slides">
          <owl-carousel-o [options]="homeSlides">
            <ng-template carouselSlide *ngFor="let item of upcommingWebinars">
              <div
                routerLink="/Webinars-Details/{{item.Id}}"
                class="main-banner"
                [ngStyle]="{
                  'background-image': 'url(' + BASE_IMG_URL.slice(0,-1) + item.ImageUrl + ')',
                  'height': '300px'
                }"
              >
                <div class="d-table">
                  <div class="d-table-cell">
                    <div class="container">
                      <div class="main-banner-content">
                        <span class="sub-title">{{ item.Category_Name }}</span>
                        <h1>{{ item.Title }}</h1>
                        <p>{{ item.Description }}</p>
                     
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
           
          </owl-carousel-o>
        </div>
        
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6" style="background-color: bisque; border: 1px #000000 solid;" *ngIf="upcommingWebinars?.length == 0">
        <div class="home-slides" >
          <div  class="main-banner"> 
            <h4>No upcomming Webinars</h4>
          </div>

        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6"  *ngIf="PreviousWebinars?.length > 0">
        <div class="home-slides">
          <owl-carousel-o [options]="homeSlides">
            <ng-template carouselSlide *ngFor="let item of PreviousWebinars">
              <div
                routerLink="/Webinars-Details/{{item.Id}}"
                class="main-banner"
                [ngStyle]="{
                  'background-image': 'url(' + BASE_IMG_URL.slice(0,-1) + item.ImageUrl + ')',
                  'height': '300px'
                }"
              >
                <div class="d-table">
                  <div class="d-table-cell">
                    <div class="container">
                      <div class="main-banner-content">
                        <span class="sub-title">{{ item.Category_Name }}</span>
                        <h1>{{ item.Title }}</h1>
                        <p>{{ item.Description }}</p>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
       
          </owl-carousel-o>
        </div>
        
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6" style="background-color: bisque; border: 1px #000000 solid;" *ngIf="PreviousWebinars?.length == 0">
        <div class="home-slides">
          <h4>No Previous Webinars</h4>
        </div>
      </div>
    </div> -->
    
<div class="row" *ngIf="PreviousWorkShops?.length > 0">

  <div class="card col-lg-4"   *ngFor="let item of PreviousWorkShops">
    <div class="blob"></div>
      <span class=""><img class=" img img-circle" src="{{BASE_IMG_URL.slice(0,-1) + item.ImageUrl}}" /> </span>
      <h4>{{ item.Title }}</h4>
      <div>
        <button style="width: 100px;" routerLink="/Workshope-Details/{{item.Id}}" class="btn btn-primary">More...</button>
      </div>
      
    </div>

</div>

  </div>
</div>
