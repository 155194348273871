import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-funfacts',
  templateUrl: './funfacts.component.html',
  styleUrls: ['./funfacts.component.scss'],
})
export class FunfactsComponent implements OnInit {
  UserCount: number;
  WorkshopsCount: number;
  WebinarsCount: number;
  CaseStudiesCount: number;
  DocumentsRepoCount: number;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.http
      .get(`${environment.BASE_URL}Api/Statistics/GetWebSiteStatistics`)
      .subscribe((data: any) => {
        this.UserCount = data?.UserCount;
        this.WorkshopsCount = data?.WorkshopsCount;
        this.WebinarsCount = data?.WebinarsCount;
        this.CaseStudiesCount = data?.CaseStudiesCount;
        this.DocumentsRepoCount = data?.DocumentsRepoCount;
      });
  }
}
