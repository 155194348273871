export class Experiment{
    constructor()
    {
        this.Description="";
        this.Id=0;
        this.Name="";
        this.S_CaseStudiesExperimentsFiles=[];
        this.S_CaseStudiesExperiments_Img=[];
        this.S_CaseStudiesExperiments_Video=[];
    }
    Id: number;
    Name: string;
    Description:string;
    S_CaseStudiesExperiments_Img:S_CaseStudiesExperiments_Img[];
    S_CaseStudiesExperiments_Video:S_CaseStudiesExperiments_Video[];
    S_CaseStudiesExperimentsFiles:S_CaseStudiesExperimentsFiles[]
}

export interface S_CaseStudiesExperiments_Img
{
Id: number;
ExperimentsId: number;
Title: string;
Url: string;
}
export interface S_CaseStudiesExperiments_Video
{
Id: number;
ExperimentsId: number;
Title: string;
Url: string;
}
export interface S_CaseStudiesExperimentsFiles
{
Id: number;
ExperimentsId: number;
Title: string;
Url: string;
FiletypeId:number;
}