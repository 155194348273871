<header class="header-area">
  <!-- <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <ul class="top-header-contact-info">
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <span>Contact support</span>
                            <a href="tel:01062233218">+2 01062233218</a>
                        </li>
                        <li>
                            <i class='bx bx-map'></i>
                            <span>Our location</span>
                            <a href="#">Cairo, Egypt</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <span>Contact email</span>
                            <a href="mailto:info@SigmaNexus.com">info@SigmaNexus.com</a>
                        </li>
                    </ul>
                </div>

                <div class="col-lg-4">
                    <div class="top-header-btn">
                        <a routerLink="/login" class="default-btn"><i class='bx bx-log-in icon-arrow before'></i><span class="label">Log In</span><i class="bx bx-log-in icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
  <div
    class="navbar-area"
    ngStickyNav
    stickyClass="sticky-box-shadow"
    ngStickyNav
  >
    <div>
      <nav
        class="navbar navbar-expand-lg navbar-light bg-light"
        [class.active]="classApplied"
      >
        <a class="navbar-brand" routerLink="/"
          ><img src="assets/img/medium.png" alt="logo" style="max-width: 20%"
        /></a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
        <div class="collapse navbar-collapse" style="flex-basis: 100% !important;" id="navbarSupportedContent">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a
                routerLink="/"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"> <i class="bx bx-home-circle" style="margin-right: 3px;"></i> Home</a>
            </li>

            <li class="nav-item">
              <a href="javascript:void(0)" class="nav-link"
                >  <i class="bx bxs-landmark" style="margin-right: 3px;"></i> HUB <i class="bx bx-chevron-down"></i
              ></a>
              <ul class="dropdown-menu">
                <li class="nav-item">
                  <a
                    routerLink="/Case-Studies"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Case Studies</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    routerLink="/Dissemenation-material"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Dissemenation Material</a
                  >
                </li>
                
                <!-- <li class="nav-item">
                  <a
                    routerLink="/Nexus-Calculator"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Nexus Calculator
                  </a>
                </li> -->
                <li class="nav-item">
                  <a
                    routerLink="/Documents-Repository"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Documents Repository
                  </a>
                </li>
          
                <li class="nav-item">
                  <a
                    routerLink="/BMC"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Business model canvas
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    routerLink="/VPC"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Value Proposition Canvas
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    routerLink="/Links"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Links
                  </a>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a href="javascript:void(0)" class="nav-link"
                > <i class="bx bxs-photo-album" style="margin-right: 3px;"></i> MEDIA CENTER <i class="bx bx-chevron-down"></i>
              </a>
              <ul class="dropdown-menu">
                <li class="nav-item">
                  <a
                    routerLink="/Workshope"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Workshops
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    routerLink="/Webinars"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Webinars
                  </a>
                </li>
                <!-- <li class="nav-item"><a routerLink="/empty-page/Project Meetings" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Project Meetings</a></li> -->
                <!-- <li class="nav-item">
                  <a
                    routerLink="/Focusgroups"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Focus groups
                  </a>
                </li> -->
                <li class="nav-item">
                  <a
                    routerLink="/Albums"
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Albums
                  </a>
                </li>
                <!-- <li class="nav-item">
                  <a
                    routerLink="/empty-page/Videos          "
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    >Videos
                  </a>
                </li> -->
              </ul>
            </li>
            <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">INNOVOATION CLUB <i class='bx bx-chevron-down'></i>  </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a href="http://nexus-club.futurecore.net" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Food Club     </a></li>
                                <li class="nav-item"><a href="http://nexus-club.futurecore.net" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Water Club    </a></li>
                                <li class="nav-item"><a href="http://nexus-club.futurecore.net" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Ecosystem Club</a></li>
                                <li class="nav-item"><a href="http://nexus-club.futurecore.net" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Nexus Club    </a></li>
                            </ul>
                        </li> -->
            <li class="nav-item">
              <a
                routerLink="/Forum-Page"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <i class="bx bx-globe" style="margin-right: 3px;"></i> Community</a>
            </li>

            <li class="nav-item">
              <a
                routerLink="/Game-Page"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                > <i class="bx bx-joystick" style="margin-right: 3px;"></i> GAME</a
              >
            </li>
            <!-- <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Calculators  </a></li> -->

            <!-- <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li> -->
          </ul>
        </div>
        <div class="others-option">
          <!-- <div class="dropdown language-switcher d-inline-block" [class.active]="classApplied2">
                        <button class="dropdown-toggle" type="button" (click)="toggleClass2()">
                            <img src="assets/img/us-flag.jpg" class="shadow" alt="image">
                            <span>En <i class='bx bx-chevron-down'></i></span>
                        </button>
                        <div class="dropdown-menu">
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/EG-Egypt-Flag-icon.png" class="shadow-sm" alt="flag">
                                <span>AR</span>
                            </a>
                           
                        </div>
                    </div> -->

          <!-- <div class="search-box d-inline-block">
            <i class="bx bx-search" (click)="toggleClass3()"></i>
          </div> -->
        </div>
      </nav>
    </div>
  </div>
</header>

<div class="search-overlay" [class.active]="classApplied3">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="search-overlay-layer"></div>
      <div class="search-overlay-layer"></div>
      <div class="search-overlay-layer"></div>
      <div class="search-overlay-close" (click)="toggleClass3()">
        <span class="search-overlay-close-line"></span>
        <span class="search-overlay-close-line"></span>
      </div>
      <div class="search-overlay-form">
        <form>
          <input
            type="text"
            class="input-search"
            placeholder="Search here..."
          />
          <button type="submit"><i class="bx bx-search-alt"></i></button>
        </form>
      </div>
    </div>
  </div>
</div>
