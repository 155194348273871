import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from "@angular/router";
import { ChartOptions } from 'chart.js/dist/types/index';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CaseStudyService } from "src/app/services/case-study.service";
import { environment } from "src/environments/environment";
import { ChartConfiguration, ChartType } from "chart.js";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-case-studies-details',
  templateUrl: './case-studies-details.component.html',
  styleUrls: ['./case-studies-details.component.scss']
})
export class CaseStudiesDetailsComponent implements OnInit {

ChartType:number=0;

  public options: any = {
	legend: { position: 'left' },
	responsive: true,
  }
  public loptions: any = {
	responsive: false
  }
  
  public pieChartLabels = [];
  public pieChartDatasets = [];
  
  public pieChartLegend = true;
  public pieChartPlugins = [];

  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: [],
    datasets: []
  };

  constructor(private caseStudy: CaseStudyService, private route: ActivatedRoute,private sanitization:DomSanitizer) { }
   id: number;
   num:number;
   data: any;
   chart:any;
   BASE_IMG_URL:string = environment.BASE_URL;
  ngOnInit(): void {
	this.route.paramMap.subscribe((params: ParamMap) => {
		this.id = +params.get('pid');
	  })
	  
    this.caseStudy.getCaseStudyByID(this.id).subscribe((data: any) => 
	this.data = data);
	
     
  }
  homeSlides: OwlOptions = {
		loop: true,
		nav: true,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
		items: 1,
		autoHeight: true,
		navText: [
			"<i class='bx bx-chevron-left'></i>",
			"<i class='bx bx-chevron-right'></i>"
		]
    }


	getlink(link:any):SafeResourceUrl
	{
     if(String(link).includes("youtube"))
	 {
          return this.sanitization.bypassSecurityTrustResourceUrl(link);
	 }
	 else
	 {
	    return this.sanitization.bypassSecurityTrustResourceUrl(this.BASE_IMG_URL.slice(0,-1)+link);
	 }

	}
	choosechart(chartid:any)
	{
		this.pieChartLabels=[];
		this.pieChartDatasets=[];
		var dataa=[];
		var label="";


		this.caseStudy.getChartByID(chartid).subscribe(
			d => {
			  if(d!=null)
			  this.chart = d;

			  this.ChartType=this.chart.Type;
			  label=this.chart.Name;
			 this.chart.data.forEach(element =>
			 {
				 this.pieChartLabels.push(element.XValues);
				 dataa.push(element.YValues);
				 this.pieChartDatasets=[ {data: dataa} ];
			 });

			this.lineChartData.labels=this.pieChartLabels;
			this.lineChartData.datasets=[ {data: dataa ,
				label: label,
				fill: true,
				tension: 0.5,
				borderColor: 'black',
				backgroundColor: 'rgba(255,0,0,0.3)'
			} ];


			},
			error => {
			});


		
	}
}
