<app-header-style-two></app-header-style-two>

    <div class="container" style="margin-top: 110px; padding: 5%;">
    <h2 style="color: brown;">Welcome To Our Community</h2>
    <p>we will redirect you in ({{seconds}}) seconds .....</p>





    </div>
