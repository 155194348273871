<div class="container-fluid">
<div class="row">
<nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
<div class="sidebar-sticky pt-3">
<ul class="nav flex-column">
<li class="nav-item">
<a class="nav-link active" href="#">
<span data-feather="home"></span>
Dashboard
</a>
</li>
<li class="nav-item">
<a class="nav-link" href="#">
<span data-feather="image"></span>
Images
</a>
</li>
<li class="nav-item">
<a class="nav-link" href="#">
<span data-feather="video"></span>
Videos
</a>
</li>
<li class="nav-item">
<a class="nav-link" href="#">
<span data-feather="flask"></span>
Experiments
</a>
</li>
<li class="nav-item">
<a class="nav-link" href="#">
<span data-feather="database"></span>
Data
</a>
</li>
<li class="nav-item">
<a class="nav-link" href="#">
<span data-feather="link"></span>
Links
</a>
</li>
<li class="nav-item">
<a class="nav-link" href="#">
<span data-feather="book"></span>
Description
</a>
</li>
<li class="nav-item">
<a class="nav-link" href="#">
<span data-feather="grid"></span>
WEF Nexus
</a>
<ul class="submenu">
<li><a href="#">Relations</a></li>
<li><a href="#">Conflicts</a></li>
</ul>
</li>
</ul>
</div>
</nav>

<main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
<h1 class="h2">Case Study Details</h1>
</div>
<!--Tab Content Goes Here -->







</main>
</div>
</div>