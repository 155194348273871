<app-header-style-two></app-header-style-two>      
<div class=" page-title-area page-title-style-three item-bg2">
  <div class="container">
    <div class="page-title-content">
            <ul>
                <li>HUB</li>
                <li>Value Proposition Canvas</li>
            </ul>
              <h2>Value Proposition Canvas</h2>          
        </div>      
  </div>
</div>
<div class="container" style="margin-top: 20px;">

    <mat-stepper orientation="vertical">
        <mat-step>
          <ng-template matStepLabel>1. Gains. </ng-template>
          <ng-template matStepContent>
            <div class="row mb-30">
              <p>the benefits which the customer expects and needs, what would delight customers and the things which may increase likelihood of adopting a value proposition.</p>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Short text Gains</label><input class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.S_Gains" type="text" placeholder="Enter short text Gains" /></div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Long text Gains</label><textarea  class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.Gains" type="text" placeholder="Enter long text  Gains" ></textarea></div>
              </div>
            </div>
            
            <button class="btn btn-info" mat-button matStepperNext>Next</button>
          </ng-template>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>2. Pains .</ng-template>
          <ng-template matStepContent>
            <div class="row mb-30">
              <p>the negative experiences, emotions and risks that the customer experiences in the process of getting the job done. </p>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Short text Pains </label><input class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.S_Pains " type="text" placeholder="Enter short text Pains " /></div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Long text Pains </label><textarea  class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.Pains " type="text" placeholder="Enter long text  Pains " ></textarea></div>
              </div>
            </div>
            <button mat-button matStepperPrevious>Back</button>
            <button class="btn btn-info" mat-button matStepperNext>Next</button>
          </ng-template>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>3. Customer jobs .</ng-template>
          <ng-template matStepContent>
            <div class="row mb-30">
              <p> the functional, social and emotional tasks customers are trying to perform, problems they are trying to solve and needs they wish to satisfy.</p>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Short text Customer jobs</label><input class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.S_Customer_jobs" type="text" placeholder="Enter short text Customer jobs" /></div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Long text Customer jobs</label><textarea  class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.Customer_jobs" type="text" placeholder="Enter long text  Customer jobs" ></textarea></div>
              </div>
            </div>
            <button mat-button matStepperPrevious>Back</button>
            <button class="btn btn-info" mat-button matStepperNext>Next</button>
          </ng-template>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>4. Gain creators.</ng-template>
          <ng-template matStepContent>
            <div class="row mb-30">
              <p>how the product or service creates customer gains and how it offers added value to the customer.
              </p>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Short text Gain creators</label><input class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.S_Gain_creators" type="text" placeholder="Enter short text Gain creators" /></div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Long text Gain creators</label><textarea  class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.Gain_creators" type="text" placeholder="Enter long text  Gain creators" ></textarea></div>
              </div>
            </div>
            <button mat-button matStepperPrevious>Back</button>
            <button class="btn btn-info" mat-button matStepperNext>Next</button>
          </ng-template>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>5. Pain relievers.</ng-template>
          <ng-template matStepContent>
            <div class="row mb-30">
              <p>a description of exactly how the product or service alleviates customer pains.              </p>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Short text Pain relievers</label><input class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.S_Pain_relievers" type="text" placeholder="Enter short text Pain relievers" /></div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Long text Pain relievers</label><textarea  class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.Pain_relievers" type="text" placeholder="Enter long text  Pain relievers" ></textarea></div>
              </div>
            </div>
            <button mat-button matStepperPrevious>Back</button>
            <button class="btn btn-info" mat-button matStepperNext>Next</button>
          </ng-template>
        </mat-step>


        <mat-step>
          <ng-template matStepLabel>6. Products and services.</ng-template>
          <ng-template matStepContent>
            <div class="row mb-30">
              <p>the products and services which create gain and relieve pain, and which underpin the creation of value for the customer.</p>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Short text Products and services</label><input class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.S_Products_and_services" type="text" placeholder="Enter short text Products and services" /></div>
              </div>
              <div class="col-md-6">
                <div class="form-group"><label class="small mb-1" for="inputName">Long text Products and services</label><textarea  class="form-control py-6" id="inputName" [ngModelOptions]="{standalone:true}" [(ngModel)]="mainObject.Products_and_services" type="text" placeholder="Enter long text  Products and services" ></textarea></div>
              </div>
            </div>
            <button mat-button matStepperPrevious>Back</button>
            <button class="btn btn-info" mat-button matStepperNext>Next</button>
          </ng-template>
        </mat-step>


        <mat-step>
          <ng-template matStepLabel>Finish</ng-template>
          <ng-template matStepContent>
            <p>You Can Download Your Value Proposition Canvas </p>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button (click)="Save()" class="btn btn-primary" > Download your copy</button>
          </ng-template>
        </mat-step>

      </mat-stepper>


</div>