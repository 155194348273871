import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WorkshopsService {
  constructor(private http: HttpClient) {}

  getAllWorkshops() {
    return this.http.get<any>(
      `${environment.BASE_URL}/Api/WorkShops/getAllWorkShops`
    );
  }

  getWorkshopByID(id: number) {
    return this.http.get<any>(
      `${environment.BASE_URL}/Api/WorkShops/${id}`
    );
  }

  getPreviousWorkshops() {
    return this.http.get<any>(
      `${environment.BASE_URL}/Api/WorkShops/getPreviousWorkShops`
    )
  }

  getUpcomingWorkShops() {
    return this.http.get<any>(
      `${environment.BASE_URL}/Api/WorkShops/getNextWorkShops`
    )
  }
}
