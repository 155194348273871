import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-home-page-two',
  templateUrl: './home-page-two.component.html',
  styleUrls: ['./home-page-two.component.scss'],
})
export class HomePageTwoComponent implements OnInit {
  contactForm = this.formBuilder.group({
    Name: '',
    Email: '',
   
  });

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {}

  onSubmit(): void {
    console.warn('Your order has been submitted', this.contactForm.value);
    this.contactForm.reset();
  }
}
