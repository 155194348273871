<app-header-style-two></app-header-style-two>
<h1 style="margin-top: 120px; text-align: center;">Mazra3aty Game</h1>

    <div class="container" style="text-align: center;" >
      <div class="row" style="background-color: black;padding: 10px;">
        <div class="col-lg-4">  </div>

        <div class="col-lg-2">
          <a href="../../../../../assets/MazWin.zip" target="_blank" download="MazWin.zip"  >
          <img class="img-icon" alt="null" src="../../../../../assets/img/win-down-logo.png">
          </a>
        </div>
        <div class="col-lg-2">
          <a href="../../../../../assets/Maz.apk" target="_blank" download="Maz.apk" >
          <img class="img-icon" alt="null" src="../../../../../assets/img/android-down.png">
          </a>
        </div>
        <div class="col-lg-4">  </div>
        <!-- <div class="col-lg-2">
          <img class="img-icon" alt="null" src="https://static.teamviewer.com/resources/2021/02/monitor-icon-mac.png">
        </div>
        <div class="col-lg-2">
          <img class="img-icon" alt="null" src="https://static.teamviewer.com/resources/2021/02/monitor-icon-ios.png">
        </div>
        <div class="col-lg-2"></div> -->
      </div>

      <!-- <div class="row" style="margin-top: 20px;">
        <div class="col-lg-12">
        <a (click)="playnow()" id="playnow" class="default-btn" style="border-radius: 17px !important;  "><i class='bx bx-joystick label' style="font-size: large; margin-right: 5px; "></i><span  class="label">Play Now</span></a>
        </div>
      </div> -->


      <div class="row" style="margin-top: 20px;">
      <div class="col-lg-12">
        <div id="unity-container" class="unity-desktop" [style.visibility]="Play ? 'visible' : 'hidden'">
          <canvas id="unity-canvas" width=960 height=600></canvas>
          <div id="unity-loading-bar">
            <div id="unity-logo"></div>
            <div id="unity-progress-bar-empty">
              <div id="unity-progress-bar-full"></div>
            </div>
          </div>
          <div id="unity-warning"> </div>
          <div id="unity-footer">
            <div id="unity-fullscreen-button"></div>
            <div id="unity-build-title">Mazra3aty-Game (Full Screen =>)</div>
          </div>
        </div>
      </div>
      </div> 

    

      
      </div>
       
    


