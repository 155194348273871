import { Component, OnInit } from '@angular/core';
import { BMC } from '../Models/BMC.model';
import { publicService } from '../services/public-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bmc',
  templateUrl: './bmc.component.html',
  styleUrls: ['./bmc.component.scss']
})
export class BMCComponent implements OnInit {
  mainObject:BMC;
  constructor(public service: publicService) 
  {
     this.mainObject=new BMC();
  }

  ngOnInit(): void {
  }

Save()
{
  this.service.post(this.mainObject, "Api/BMC", "").subscribe(
    (res) => {
      this.mainObject=res;

      this.service.get("Api/BMC/print/"+this.mainObject.Id, "","").subscribe(
        (rep) => {

          window.location.href = environment.BASE_URL+""+rep.pdfUrl;
        

        },
        (err) => { 
    
        })
      
    },
    (err) => { 

    })

}
}
