<app-header-style-two></app-header-style-two>
<div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-8">

                <div class="blog-details-area ptb-100">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <h4 style="margin-top: 20px; border: 1px blue solid; border-radius: 10px; background-color: #90ccef; padding: 5px;">{{data.Title}}</h4>
                
                                <div class="blog-details-desc">
                                    <div class="article-image">
                                      <img *ngIf="data.ImageUrl" style="height: 400px; width: 100%; border-radius: 50px; margin: 20px; margin-left: auto; margin-right: auto; display: block; border: white solid 2px;" src="{{Base_url}}{{data.ImageUrl}}" alt="image">
                                        
                                      <iframe *ngIf="data.VideoUrl" 
                                      style="width: 100% ; height: 100%; min-height: 300px; border: 8px #183e8d solid;padding: 3px;"
                                     
                                      [src]="transform(data.VideoUrl)"
                                      
                                      title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                   
                                    </div>
                                    <div class="article-content">
                                      
                                       
                                        <h5>Date: {{
                                            data.Date.slice(0, data.Date.indexOf('T'))
                                          }}</h5>
                                        <p>{{data.LongDescription}}</p>
                                        <h5>External Links</h5>
                                        <div *ngFor="let item of splitDescription(data.ExternalLinks) ; let i = index">
                                            <a target="_blank"  href="{{item}}">Link {{i+1}}</a>
                                        </div>
                                        
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            </div>

            <div class="col-lg-4 col-md-4" >

                 
                 <ul class="list-group ptb-100" style="margin-top: 20px;"  >
                    <li style="text-decoration: none; display: block; margin-bottom: 10px;"><h4  style="border: 1px blue solid; border-radius: 10px; background-color: #90ccef; padding: 5px;">Sigma Nexus News</h4></li>
                    <li class="list-group-item " *ngFor="let item of dataAll">
                      <h3><a href="/single-blog/{{item.Id}}">{{item.Title}}</a></h3>  
                      <p style="color: darkgray;">Date: {{item.Date.slice(0, data.Date.indexOf('T'))}}</p> 
                    </li>
                    
                  </ul>
                    
                 
            </div>
            
        </div>
</div>




