import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Galleries } from 'src/app/Models/Galleries.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-albums-details',
  templateUrl: './albums-details.component.html',
  styleUrls: ['./albums-details.component.scss']
})
export class AlbumsDetailsComponent implements OnInit {

  item:Galleries;
  BASE_IMG_URL = environment.BASE_URL;
  id: number;
  homeSlides: OwlOptions = {
    loop: true,
    nav: true,
    dots: true,
    autoplayHoverPause: true,
    autoplay: true,
    items: 1,
    autoHeight: true,
    navText: [
      "<i class='bx bx-chevron-left'></i>",
      "<i class='bx bx-chevron-right'></i>",
    ],
  };


 
  
  constructor(public http: HttpClient ,private route: ActivatedRoute) 
  { }

  ngOnInit(): void {


    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = +params.get('pid');		
      });

    this.http
    .get(`${this.BASE_IMG_URL}Api/Gallary/`+this.id)
    .subscribe((dataAll: any) => (this.filldata(dataAll))
    
    );

    

  }

  filldata(dataAll: any)
  {
    this.item=dataAll;

  }





}
