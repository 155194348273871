import { Observable, Subject } from 'rxjs';
import { OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root',
})
export class publicService {
  httpOptions = {};

  private requestsNotificationChangeSource = new Subject<any>();
  requestsChangeEmitted$ = this.requestsNotificationChangeSource.asObservable();

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        //'Authorization': 'Bearer ' + this.msalService.GetAccessToken()
      }),
    };
  }

  getAll(apiController: string): Observable<any[]> {
    this.httpOptions;

    return this.http.get<any[]>(
      environment.BASE_URL + apiController,
      this.httpOptions
    );
  }



  get(apiController: string, params: any, paramsName: string) {
    return this.http.get<any>(environment.BASE_URL + apiController, {
      params: {
        [paramsName]: params,
      },
    });
  }
  
  getData(apiController: string, params?: any): Observable<any> {
    return this.http.get<any>(environment.BASE_URL + apiController, {
      params,
    });
  }

  Remove(data: any, apiController: string, action?: string): Observable<any> {
    if (action) {
      return this.http.delete<any>(
        environment.BASE_URL + apiController + '/' + action,
        data
      );
    } else {
      return this.http.post<any>(environment.BASE_URL + apiController, data, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('Token') },
      });
    }
  }

  // add
  post(data: any, apiController: string, action?: string): Observable<any> {
    if (action) {
      return this.http.post<any>(
        // environment.serverUrl + apiController + '/' + action, data,
        // {headers:{'Authorization': 'Bearer ' + localStorage.getItem('Token')}}
        environment.BASE_URL + apiController + '/' + action,
        data
      );
    } else {
      // tslint:disable-next-line: max-line-length
      return this.http.post<any>(environment.BASE_URL + apiController, data);

    }
  }

 

  put(data: any, apiController: string, action?: string): Observable<any> {
    if (action == undefined || action == null)
      return this.http.put<any>(environment.BASE_URL + apiController, data);
    return this.http.put<any>(
      environment.BASE_URL + apiController + '/' + action,
      data
    );
  }

  

}
