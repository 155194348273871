<app-header-style-two></app-header-style-two>
<app-hometwo-main-banner></app-hometwo-main-banner>

<div class="blog-area pt-100 pb-70">
  <div class="container">
    <div class="section-title">
      <!-- <span class="sub-title">SIGMA NEXUS News</span> -->
      <header> <h1>Last News</h1></header>
     
    </div>
    <app-blog></app-blog>
  </div>
</div>


<div class="testimonials-area pt-10 pb-70">
  <div class="container">
    <div class="section-title">
      <!-- <span class="sub-title">Latest research</span> -->
      
      <header> <h1>CaseStudies</h1></header>
    </div>
    <app-feedback-style-two></app-feedback-style-two>
  </div>
</div>


<app-boxes></app-boxes>

<!-- <app-hometwo-about></app-hometwo-about> -->

<!-- <div class="courses-categories-area bg-image pt-100 pb-70">
  <div class="container"> -->
<!-- <div layout="column">
      <video
        style="width: 100%"
        layout-fill
        loop="loop"
        controls="true"
        preload="auto"
      >
        <source src="assets/video.mp4" type="video/mp4" />
      </video>
    </div> -->
<div class="pt-10">
  <div class="section-title">
    <!-- <span class="sub-title">Workshops & Webinars </span> -->
    
    <header> <h1>Workshops & Webinars</h1></header>
  </div>
  <app-offer></app-offer>
</div>
<!-- <div class="section-title text-start pt-100">
  <div class="container">
    <span class="sub-title">Webinars</span>
    <h2>Browse</h2>
  </div>
</div> -->
<!-- <div class="container">
  <app-categories-style-one></app-categories-style-one>
</div> -->
<!-- </div>
</div> -->

<div class="pt-100 pb-50" style="background-image: url(../../../../assets/img/farm.jpg); background-size: cover; margin-top: 100px;">
  <div class="become-instructor-partner-area">
    <div class="container" >
        <div class="row" style="margin-top: -30px;" >
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-content bg-color">
                    <h2 style="color: gold; text-shadow: 2px 2px 4px #000000;">SIGMA NEXUS GAME</h2>
                    <p style="color: black; font-weight: bold;">Promote a balance between offering knowledge and skill-based learning with a focus on building habits</p>
                    <a routerLink="/Game-Page" class="default-btn" style="border-radius: 17px !important; margin-bottom: 20px; "><i class='bx bx-joystick label' style="font-size: large; margin-right: 5px; "></i><span  class="label">Play Now</span></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <video 
              style="width: 100% ; border: 8px #183e8d solid;padding: 3px;"
              layout-fill
              loop="loop"
              controls="true"
              preload="auto">
              <source src="assets/video.mp4" type="video/mp4" />
            </video>

            </div>
           
           
        </div>
    </div>
</div>
</div>

<!-- <div class="funfacts-area pt-100">
  <div>
    <div class="funfacts-inner">
      <app-funfacts></app-funfacts>
    </div>
  </div>
</div> -->

<!-- <app-hometwo-courses></app-hometwo-courses> -->

<!-- <app-our-mission></app-our-mission>  -->

<!-- <div class="partner-area bg-color ptb-100">
  <div class="container">
    <div class="section-title">
      <h2>Our Nexus & Partners</h2>
    </div>
    <app-partner-style-one></app-partner-style-one>
  </div> 
 </div> -->

<!-- <div class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Instructors</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>
        </div>
        <app-instructors-style-two></app-instructors-style-two>
    </div>
</div> -->
<div class="pt-100">
  <app-students-feedback-form></app-students-feedback-form>
</div>

<div   style="background-color: #12141b; border-bottom: white 1px solid; ">
  
<form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
<div class="row" style="height: 50px;">
  <div class="col-lg-3 col-md-3">
    <h3 style="color: white; text-align: left; margin-top: 8px">Join Our NewsLetter</h3>
  </div>
  <div class="col-lg-3 col-md-3 col-4">
    
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          placeholder="Your name*"
          formControlName="Name"
        />
        <span class="label-title"><i class="bx bx-user"></i></span>
      </div>
   
  </div>
  <div class="col-lg-3 col-md-3 col-4">
   
      <div class="form-group">
        <input
          type="email"
          class="form-control"
          placeholder="Your email*"
          formControlName="Email"/>
        <span class="label-title"
          ><i class="bx bx-envelope"></i
        ></span>
      </div>
    
  </div>

  <div class="col-lg-3 col-md-3 col-4">
    
      <button type="submit" class="default-btn">
        <i class="bx bx-paper-plane icon-arrow before"></i
        ><span class="label">Join Us</span
        ><i class="bx bx-paper-plane icon-arrow after"></i>
      </button>
    </div>
  </div>
  </form>

</div>
<!-- <app-webinar-countdown></app-webinar-countdown> -->
