import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { environment } from "src/environments/environment";
@Component({
  selector: 'app-students-feedback-form',
  templateUrl: './students-feedback-form.component.html',
  styleUrls: ['./students-feedback-form.component.scss'],
})
export class StudentsFeedbackFormComponent implements OnInit {
  contactForm = this.formBuilder.group({
    Date: new Date(),
    Name: '',
    LastName: '',
    Email: '',
    Message: '',
    Title: 'Feedback',
  });

  constructor(private formBuilder: FormBuilder, private http: HttpClient) {}
  ngOnInit(): void {}

  onSubmit(): void {
    alert('Your Msg has been Sent');
    this.http
      .post(`${environment.BASE_URL}Api/ContactUs`, this.contactForm.value)
      .subscribe((data) => console.log(data));
    this.contactForm.reset();
  }
}
