import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from "ngx-owl-carousel-o";
import { WebinarsService } from "src/app/services/webinars.service";
import { WorkshopsService } from "src/app/services/workshops.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
})
export class OfferComponent implements OnInit {
  upcommingWorkShops: any[] = [];
  PreviousWorkShops: any[] = [];
  upcommingWebinars: any[] = [];
  PreviousWebinars: any[] = [];
  BASE_IMG_URL = environment.BASE_URL;
  constructor(private http: HttpClient, public workshops: WorkshopsService, public webinars: WebinarsService) {}

  ngOnInit(): void {
    this.workshops.getUpcomingWorkShops().subscribe(data => this.upcommingWorkShops = data);
    this.workshops.getPreviousWorkshops().subscribe(data1 => this.PreviousWorkShops = data1);
    this.webinars.getUpcomingWebinars().subscribe(data2 => this.upcommingWebinars = data2);
    this.webinars.getPreviousWebinars().subscribe(data3 => this.PreviousWebinars = data3);
  }

  homeSlides: OwlOptions = {
    loop: true,
    nav: true,
    dots: true,
    autoplayHoverPause: true,
    autoplay: true,
    items: 1,
    autoHeight: true,
    navText: [
      "<i class='bx bx-chevron-left'></i>",
      "<i class='bx bx-chevron-right'></i>",
    ],
  };
}
