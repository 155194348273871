export class VPC{
    constructor()
    {
        this.Id=0;
        this.Gains ="";
        this.Pains ="";
        this.Customer_jobs="";
        this.Gain_creators="";
        this.Pain_relievers="";
        this.Products_and_services="";
    

        this.S_Gains ="";
        this.S_Pains ="";
        this.S_Customer_jobs="";
        this.S_Gain_creators="";
        this.S_Pain_relievers="";
        this.S_Products_and_services="";
    
       
    }
    Id: number;
    Gains : string;
    Pains : string;
    Customer_jobs: string;
    Gain_creators: string;
    Pain_relievers: string;
    Products_and_services: string;
    S_Gains : string;
    S_Pains : string;
    S_Customer_jobs: string;
    S_Gain_creators: string;
    S_Pain_relievers: string;
    S_Products_and_services: string;
  
}