import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebinarsService {
  constructor(private http: HttpClient) {}

  getAllWebinars() {
    return this.http.get<any>(
      `${environment.BASE_URL}/Api/Webinars/getAllWebinars`
    );
  }

  getWebinarByID(id: number) {
    return this.http.get<any>(
      `${environment.BASE_URL}/Api/Webinars/${id}`
    );
  }

  getPreviousWebinars() {
    return this.http.get<any>(
      `${environment.BASE_URL}/Api/Webinars/getPreviousWebinars`
    )
  }

  getUpcomingWebinars() {
    return this.http.get<any>(
      `${environment.BASE_URL}/Api/Webinars/getNextWebinars`
    )
  }
}
