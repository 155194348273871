<app-header-style-two></app-header-style-two>       


<div class="page-title-area page-title-style-three item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li>HUB</li>
                <li>Dissemenation material</li>
            </ul>
            <h2>Dissemenation material</h2>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="instructor-area pt-100 pb-70" >
            <div class="container">

                <h1 style="color: gold; text-shadow: 1px 1px black;">Egypt material </h1>

                <div class="row">

                    <div class="col-lg-3 col-md-4 col-sm-4" *ngFor="let item of data|Filter:filterCasesEgypt">
        
                        <div class="card">
                            <div class="card-details">
                              <p class="text-title">{{ item.Title}}</p>
                             
                              <div class="text-body">
                                <img src="assets/img/medium.png" alt="logo" width="100px"/>
                                <p>{{ item.Description}}</p>
                              </div>
                            </div>
                            <a class="card-button" href="{{Base_URL}}{{item.Url}}" >Download File</a>
                          </div>
        
        
          
                    </div>
                 </div>
                 <hr>
                 <h1 style="color: gold; text-shadow: 1px 1px black;">Greece material </h1>

                 <div class="row">
 
                     <div class="col-lg-3 col-md-4 col-sm-4" *ngFor="let item of data|Filter:filterCasesGreece">
         
                         <div class="card">
                             <div class="card-details">
                               <p class="text-title">{{ item.Title}}</p>
                              
                               <div class="text-body">
                                 <img src="assets/img/medium.png" alt="logo" width="100px"/>
                                 <p>{{ item.Description}}</p>
                               </div>
                             </div>
                             <a class="card-button" [href]="getlink(item.Url)"  >Download File</a>
                           </div>
         
         
           
                     </div>
                  </div>


            </div>
        </div>



</div>
</div>

