import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-focusgroups-details',
  templateUrl: './focusgroups-details.component.html',
  styleUrls: ['./focusgroups-details.component.scss']
})
export class FocusgroupsDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
