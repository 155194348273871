import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-albums-list',
  templateUrl: './albums-list.component.html',
  styleUrls: ['./albums-list.component.scss']
})
export class AlbumsListComponent implements OnInit {

  BASE_IMG_URL = environment.BASE_URL;
  Galleries:[]=[];
  homeSlides: OwlOptions = {
    loop: true,
    nav: true,
    dots: true,
    autoplayHoverPause: true,
    autoplay: true,
    items: 1,
    autoHeight: true,
    navText: [
      "<i class='bx bx-chevron-left'></i>",
      "<i class='bx bx-chevron-right'></i>",
    ],
  };
  constructor(public http: HttpClient) { }

  ngOnInit(): void {

    this.http
    .get(`${this.BASE_IMG_URL}Api/Gallary/getAllGalleries`)
    .subscribe((dataAll: any) => (this.Galleries = dataAll));
  }
 

   

  
  
}
