import { Component, OnInit } from '@angular/core';
import { subscribeOn } from 'rxjs/operators';
import { WebinarsService } from 'src/app/services/webinars.service';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-webinars-list',
  templateUrl: './webinars-list.component.html',
  styleUrls: ['./webinars-list.component.scss'],
})
export class WebinarsListComponent implements OnInit {
  data: any[] = [];
  BASE_URL = environment.BASE_URL;
  constructor(public webinars: WebinarsService) {}

  ngOnInit(): void {
    this.webinars.getAllWebinars().subscribe((data: any) => (this.data = data));
  }
}
